import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { DateUtility, HolistaUtils } from 'src/app/utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  currentYear = this._dateUtility.getCurrentYear();
  networkConfig: any;
  isSmallScreen = window.innerWidth <= 676;
  year = new Date().getFullYear();
  private versionUrl = 'assets/version.json';
  version: string;

  @Input() set config(data) {
    if (data?.code) {
      this.networkConfig = data;
    }
  }

  constructor(
    public _dateUtility: DateUtility,
    public _holistaUtils: HolistaUtils,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    this.getVersion().subscribe((data) => {
      this.version = data.version;
    });
  }

  onLinkClick(link: string, newTab?: boolean) {
    this._holistaUtils.customRedirect(link, newTab);
  }

  getVersion(): Observable<any> {
    return this._http.get(this.versionUrl);
  }
}
