import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipFormat',
})
export class ZipFormatPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) return '';

    const zip = value.toString().replace(/\D/g, ''); // Ensure only digits
    return zip.length > 5 ? `${zip.substring(0, 5)}-${zip.substring(5)}` : zip;
  }
}
