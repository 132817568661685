<nav class="navbar navbar-expand-lg navbar-white bg-white">
  <section class="container container-outer">
    <span class="cursor-pointer" (click)="navigateToHome()">
      <img [src]="networkConfig?.logo || logoPath" alt="" class="me-2" width="auto" [style.height]="HOLISTA_CONSTANT.LAYOUT.HEADER_HEIGHT" />
    </span>
    <ul class="nav ms-auto my-lg-0">
      <li class="nav-item">
        <span class="nav-link text-primary cursor-pointer border-end"
          (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_ABOUT, true)">About Us
        </span>
      </li>
      <li class="nav-item">
        <a class="nav-link text-primary border-end cursor-pointer" (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_GET_STARTED, true)">Let's Get Started</a>
      </li>
      <li class="nav-item d-inline d-sm-none d-md-none d-lg-none ps-1 border-end">
        <span class="nav-link text-primary cursor-pointer" (click)="openContactPopup($event)">Contact</span>
      </li>
    </ul>
    <div class="d-none d-sm-block d-md-block d-lg-block ps-3">
      <small>
        <!-- <p class="m-0 lh-sm">
          <a href="tel:+{{networkConfig?.phone}}" class="d-flex align-items-center justify-content-start text-reset">
            <i class="fas fa-mobile-alt text-primary"></i>
            &nbsp; <span class="fst-normal ms-1">{{networkConfig?.phone | mask: '000.000.0000'}}</span>
          </a>
        </p> -->
        <p class="m-0 lh-sm">
          <a href="mailto:{{networkConfig?.email}}" class="d-flex align-items-center justify-content-start text-reset">
            <i class="fas fa-envelope text-primary"></i>
            &nbsp; <span class="fst-normal ms-1">{{networkConfig?.email}}</span>
          </a>
        </p>
      </small>
    </div>
  </section>
</nav>
