<div class="listing-search search-listing">
  <div>
    <!-- <div *ngIf="showSuggestions && !loadingProviders">
      <div *ngIf="chipList.length; else noResults">
        <p class="mt-4 small text-muted">Were you looking for?</p>
      </div>
      <mdb-chip *ngFor="let item of chipList" (click)="bundleOptionSelected(item, true)"
        customClass="chip chip-outline btn-outline-secondary bg-white d-inline-block">
        {{ item?.displayName || item?.bundleName || item?.businessName || item?.fullName}}
      </mdb-chip>
    </div> -->
    <ng-template #noResults>
      <div *ngIf="!loadingProviders" class="text-muted d-flex row justify-content-center mx-auto p-4" style="width: 460px">
        <div class="text-center"><i class="fas fa-magnifying-glass fa-3x"></i></div>
        <h5 class="text-center py-3 mb-0">There are no {{ isSearchByProvider ? 'providers' : 'services'}} matching your search criteria</h5>
        <p class="text-center">Search Suggestions:</p>
        <ul style="width: 290px">
          <li><span style="font-size: 14px;">Check your keyword and location above</span></li>
          <li><span style="font-size: 14px;">Increase your search radius</span></li>
        </ul>
      </div>
    </ng-template>
    <div *ngIf="isFormSubmitted" class="bg-white" [style.min-height]="'calc(100vh - ' + HOLISTA_CONSTANT.LAYOUT.HEADER_HEIGHT + ' - ' + HOLISTA_CONSTANT.LAYOUT.FOOTER_HEIGHT + (isSearchByProvider ? ' - 184px' : ' - 236px') + ')'">
      <div class="container-outer mx-auto">
        <div class="row">
          <div *ngIf="!loadingProviders && mainSearchFiltersData?.providers?.length; else noResults" class="total d-flex justify-content-between px-4" [ngClass]="{'py-2': 'isTableView'}">
            <p  class="small text-muted m-0">
              {{
                mainSearchFiltersData?.providers?.length }} Result{{ mainSearchFiltersData?.providers?.length > 1 ? 's'
                : '' }} found
                for <strong>{{mainSearchFiltersData?.bundle?.displayName || mainSearchFiltersData?.bundle?.bundleName ||
                  mainSearchFiltersData?.bundle || mainSearchFiltersData?.provider?.displayName ||
                  mainSearchFiltersData?.provider?.businessName
                  || mainSearchFiltersData?.provider?.fullName ||
                  mainSearchFiltersData?.provider }}</strong>
            </p>
            <div class="d-flex gap-2" *ngIf="mainSearchFiltersData?.providers?.length">
              <button
                type="button"
                class="btn btn-outline btn-rounded btn-sm fw-bold"
                mdbRipple rippleColor="dark"
                [disabled]="isTableView"
                (click)="onChangeView()"
              >
                <i class="fas fa-list"></i> 
              </button>
              <button
                type="button"
                class="btn btn-outline btn-rounded btn-sm"
                mdbRipple rippleColor="dark"
                [disabled]="!isTableView"
                (click)="onChangeView()"
              >
                <i class="fas fa-border-all"></i> 
              </button>
            </div>
          </div>
          <!-- <form class="col-12 col-sm-4 col-md-4 col-lg-4" [formGroup]="searchAdvanceFilterForm">
            <div
              class="d-flex justify-content-sm-between justify-content-md-end justify-content-lg-end align-items-center filters"> -->
              <!-- <p class="d-flex justify-content-between align-items-center small text-muted m-0">
                <span class="me-2">Filter by Doctor</span>
                <mdb-form-control class="me-2">
                  <mdb-select formControlName="doctor" placeholder="Choose Doctor"
                    (selected)="onDoctorSelected($event)">
                    <mdb-option *ngFor="let option of doctors" [value]="option.value">{{
                      option.label
                      }}</mdb-option>
                  </mdb-select>
                </mdb-form-control>
              </p> -->
              <!-- <p class="d-flex justify-content-between align-items-center small text-muted m-0">
                <span class="me-2">Radius</span>
                <mdb-form-control>
                  <mdb-select formControlName="radius" placeholder="Distance" (selected)="onRadiusSelected($event)"
                    style="width: 140px;">
                    <mdb-option *ngFor="let option of radius" [value]="option.value">
                      {{option.label}}
                    </mdb-option>
                  </mdb-select>
                </mdb-form-control>
              </p> -->
            <!-- </div>
          </form> -->
        </div>
      </div>
      <div *ngIf="isTableView" class="overflow-auto px-2">
    <table *ngIf="loadingProviders || mainSearchFiltersData?.providers?.length" class="table container-outer mx-auto mb-0" [style.border-top]="!loadingProviders ? '1px solid #ddd' : 'none'">
          <thead>
            <tr *ngIf="loadingProviders; else showHeaders">
              <th scope="col"><app-skeleton-loader></app-skeleton-loader></th>
              <th scope="col"><app-skeleton-loader></app-skeleton-loader></th>
              <th scope="col"><app-skeleton-loader></app-skeleton-loader></th>
              <th scope="col" *ngIf="!isSearchByProvider"><app-skeleton-loader></app-skeleton-loader></th>
            </tr>
            <ng-template #showHeaders>
              <tr>
                <th scope="col">Provider Name</th>
                <th scope="col">Address</th>
                <th scope="col">Phone</th>
                <th *ngIf="!isSearchByProvider" scope="col">Distance</th>
                <th scope="col"></th>
              </tr>
            </ng-template>
          </thead>
          <tbody>
            <ng-container *ngIf="loadingProviders">
              <tr *ngFor="let i of [1,2,3,4]">
                <td>
                  <div class="d-flex align-items-center gap-2">
                    <app-skeleton-loader height="48px" width="48px" borderRadius="50%"></app-skeleton-loader>
                    <div class="d-flex row gap-2" [ngStyle]="{'width': '80%'}">
                      <div class="w-100">
                        <app-skeleton-loader></app-skeleton-loader>
                        </div>
                      <div class="w-100">
                        <app-skeleton-loader></app-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex row gap-2">
                    <app-skeleton-loader></app-skeleton-loader>
                    <app-skeleton-loader></app-skeleton-loader>
                  </div>
                </td>
                <td><app-skeleton-loader></app-skeleton-loader></td>
                <td *ngIf="!isSearchByProvider"><app-skeleton-loader></app-skeleton-loader></td>
              </tr>
            </ng-container>

            <!-- For Facility-->
             <ng-container *ngIf="!isSearchByProvider">
              <tr *ngFor="let item of mainSearchFiltersData?.providers | paginate: {itemsPerPage: listingSearchFilter?.pagination?.limit, currentPage: listingSearchFilter?.pagination?.page, totalItems: listingSearchFilter?.pagination?.totalCount}">
                <td scope="row">
                  <div class="d-flex align-items-center gap-2">
                    <div class="contact-img">
                      <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-hospital fa-lg text-primary d-flex align-items-center justify-content-center"
                        style="height: 50px; width: 50px;"></i>
                    </div>
                    <div>
                      {{ item?.facility?.displayName || item?.facility?.businessName }}
                      <p class="m-0 text-light">
                        <small>{{ item?.facility?.primaryTaxonomy && item?.facility?.primaryTaxonomy[0]?.name
                          }}</small>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="address d-flex">
                    <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                    <p class="m-0">
                      <small class="text-gray">{{ item?.facility?.address?.addressLine1 }}</small><br>
                      <small class="text-gray">{{ item?.facility?.address?.city }}, {{
                        item?.facility?.address?.state
                        }}, {{ item.facility?.address?.zip | zipFormat }}</small><br>
                      <small class="text-primary text-decoration-underline cursor-pointer"
                        (click)="getDirection(item)">(Get Directions)</small>
                    </p>
                  </div>
                </td>
                <td>{{ item?.facility?.phone && item.facility.phone !== 'N/A'
                  ? (item.facility.phone | mask: '(000) 000-0000') 
                  : 'N/A' }}</td>
                <td>{{ item?.distance }} mi.</td>
                <td>
                  <button type="button" (click)="viewDetailModal(item)"
                    class="d-block btn btn-outline-dark bg-white text-capitalize rounded"
                    style="border: 1px solid #ddd; margin-left: auto;" mdbRipple rippleColor="dark">
                    View Detail
                  </button>
                </td>
              </tr>
            </ng-container>

            <!-- For Physician -->
            <ng-container *ngIf="isSearchByProvider">
              <tr *ngFor="let item of mainSearchFiltersData?.providers | paginate: {itemsPerPage: listingSearchFilter?.pagination?.limit, currentPage: listingSearchFilter?.pagination?.page, totalItems: listingSearchFilter?.pagination?.totalCount}">
                <td scope="row">
                  <div class="d-flex align-items-center gap-2">
                    <div class="contact-img">
                      <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-user-doctor fa-lg text-primary d-flex align-items-center justify-content-center"
                        style="height: 50px; width: 50px;"></i>
                    </div>
                    <div>
                      {{ item?.physician?.fullName || getFullName(item?.physician) | slice:0:50 }}
                      <p class="m-0 text-light">
                        <small>{{ item?.physician?.primaryTaxonomy && item?.physician?.primaryTaxonomy[0]?.name
                          }}</small>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="address d-flex">
                    <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                    <p class="m-0">
                      <small class="text-gray">{{ item?.physician?.address?.addressLine1 }}</small><br>
                      <small class="text-gray">{{ item?.physician?.address?.city }}, {{
                        item?.physician?.address?.state
                        }}, {{ item.physician?.address?.zip | zipFormat }} </small><br>
                    </p>
                  </div>
                </td>
                <td>{{ item?.physician?.phone && item.physician.phone !== 'N/A'
                  ? (item.physician.phone | mask: '(000) 000-0000') 
                  : 'N/A' }}</td>
                <td *ngIf="!isSearchByProvider">{{ item?.distance }} mi.</td>
                <td>
                  <button type="button" (click)="viewDetailModal(item)"
                    class="d-block btn btn-outline-dark bg-white text-capitalize rounded"
                    style="border: 1px solid #ddd; margin-left: auto;" mdbRipple rippleColor="dark">
                    View Detail
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot *ngIf="!loadingProviders">
            <tr>
              <td colspan="5" class="border-0 px-0 py-3">
                <div class="alert d-flex gap-2 mb-0" >
                  <i class="fas fa-info-circle"></i>
                  <div class="d-flex flex-column">
                    <p class="m-0">Additional Providers and Services Coming Soon!</p>
                    <p class="m-0"> If your provider or service isn't listed, please call <a class="text-primary text-decoration-underline" href="tel:+8552846216">(855) 284-6216</a> to find out more.</p>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- <div class="class-container"> -->
        <!-- <div *ngFor="let item of mainSearchFiltersData?.providers" class="card mb-2">
          <div class="card-body py-2">
            <div class="row">
              <div class="col-sm-12 col-md-4 col-lg-4 m-0 p-0">
                <div class="d-flex">
                  <div class="contact-img me-2">
                    <i class="mx-auto d-block img-fluid rounded-circle shadow-4 fas fa-hospital fa-2x text-primary d-flex align-items-center justify-content-center"
                      style="height: 60px; width: 60px;"></i>
                  </div>
                  <div class="contact-info">
                    <div class="name-speciality">
                      <p class="h6 m-0 mt-1">{{ item?.facility?.displayName || item?.facility?.businessName }}</p>
                      <p class="m-0 text-light">
                        <small>{{ item?.facility?.primaryTaxonomy && item?.facility?.primaryTaxonomy[0]?.name }}</small>
                      </p>
                    </div>
                    <div class="address-phone mt-3">
                      <div class="address d-flex">
                        <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.facility?.address?.addressLine1 }}</small><br>
                          <small class="text-gray">{{ item?.facility?.address?.city }}, {{
                            item?.facility?.address?.state
                            }}, {{ item.facility?.address?.zip | zipFormat }}</small><br>
                          <small class="text-primary text-decoration-underline cursor-pointer"
                            (click)="openLocationModal(item)">(Get Directions)</small>
                        </p>
                      </div>
                      <div class="phone d-flex align-items-center">
                        <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.facility?.phone | mask: '000.000.0000' }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="hr d-block d-md-none d-lg-none mt-3">
              <div class="col-sm-12 col-md-8 col-lg-8 m-0 p-0">
                <owl-carousel-o [options]="customOptions">
                  <ng-container *ngFor="let slideItem of mainSearchFiltersData.physicians; let i = index">
                    <ng-template carouselSlide let-owlItem>
                      <div
                        [ngClass]="{ 'middle-slide': owlItem.isCentered, 'first-slide': i=== 0 && mainSearchFiltersData.physicians.length < 3 }"
                        class="mt-3 item">
                        <i class="mx-auto d-block img-fluid rounded-circle text-primary shadow-4 fas fa-user-md fa-2x d-flex align-items-center justify-content-center"
                          style="height: 60px; width: 60px;"></i>
                        <p class="h6 text-dark text-center m-0 mt-2">{{ slideItem?.firstName }} {{ slideItem?.lastName
                          }}
                        </p>
                        <p class="text-light text-center"><small>
                            {{ slideItem?.primaryTaxonomy && slideItem.primaryTaxonomy[0].name }}
                          </small></p>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div> -->
        <div *ngIf="!isTableView" class="container-outer mx-auto px-2">
          <div *ngIf="!loadingProviders; else loadingCards" class="row g-3">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3"
              *ngFor="let item of mainSearchFiltersData?.providers | paginate: {itemsPerPage: listingSearchFilter?.pagination?.limit, currentPage: listingSearchFilter?.pagination?.page, totalItems: listingSearchFilter?.pagination?.totalCount}">
              <div class="card position-relative" [style.min-height]="isSmallScreen ? 'auto' : getHeight(item?.facility)">
                <div class="card-body">
                  <div class="contact-img">
                    <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-lg text-primary d-flex align-items-center justify-content-center mb-2"
                      [ngClass]="isSearchByProvider ? 'fa-user-doctor' : 'fa-hospital'"
                      style="height: 64px; width: 64px;"></i>
                  </div>
                  <!-- For Facility-->
                  <div class="contact-info" *ngIf="item?.facility">
                    <div class="name-speciality">
                      <p class="h6 m-0 mt-3 cursor-pointer"
                        [mdbTooltip]="item?.facility?.displayName || item?.facility?.businessName">
                        {{ item?.facility?.displayName || item?.facility?.businessName | slice:0:50 }}
                        <span
                          *ngIf="item?.facility?.displayName?.length > 50 || item?.facility?.businessName?.length > 50">...</span>
                      </p>
                      <p class="m-0 text-light">
                        <small>{{ item?.facility?.primaryTaxonomy && item?.facility?.primaryTaxonomy[0]?.name
                          }}</small>
                      </p>
                    </div>
                    <div class="address-phone mt-1">
                      <div class="address d-flex">
                        <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.facility?.address?.addressLine1 }}</small><br>
                          <small class="text-gray">{{ item?.facility?.address?.city }}, {{
                            item?.facility?.address?.state
                            }}, {{ item.facility?.address?.zip | zipFormat}}</small><br>
                          <small class="text-primary text-decoration-underline cursor-pointer"
                            (click)="getDirection(item)">(Get Directions)</small>
                        </p>
                      </div>
                      <div class="phone mt-2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                            <p class="m-0">
                              <small class="text-gray">{{ item?.facility?.phone && item?.facility?.phone !== 'N/A'  ? (item.facility.phone | mask:
                                '(000) 000-0000')
                                : 'N/A' }}</small>
                            </p>
                          </div>
                          <div>
                            <button type="button" (click)="viewDetailModal(item)"
                              class="d-block d-sm-none d-md-none d-lg-none btn btn-outline-dark bg-white text-capitalize rounded"
                              style="border: 1px solid #ddd" mdbRipple rippleColor="dark">
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- For Physician -->
                  <div class="contact-info" *ngIf="item?.physician">
                    <div class="name-speciality">
                      <p class="h6 m-0 mt-3 cursor-pointer"
                        [mdbTooltip]="item?.physician?.displayName || item?.physician?.businessName || getFullName(item?.physician)">
                        {{ item?.physician?.fullName || getFullName(item?.physician) | slice:0:50 }}
                        <span
                          *ngIf="item?.physician?.displayName?.length > 50 || item?.physician?.businessName?.length > 50 || getFullName(item?.physician).length > 50">...</span>
                      </p>
                      <p class="m-0 text-light">
                        <small>{{ item?.physician?.primaryTaxonomy && item?.physician?.primaryTaxonomy[0]?.name
                          }}</small>
                      </p>
                    </div>
                    <div class="address-phone mt-1">
                      <div class="address d-flex">
                        <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.physician?.address?.addressLine1 }}</small><br>
                          <small class="text-gray">{{ item?.physician?.address?.city }}, {{
                            item?.physician?.address?.state
                            }}, {{ item.physician?.address?.zip | zipFormat}}</small><br>
                        </p>
                      </div>
                      <div class="phone mt-2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                            <p class="m-0">
                              <small class="text-gray">
                                {{ item?.physician?.phone && item.physician.phone !== 'N/A'
                                  ? (item.physician.phone | mask: '(000) 000-0000') 
                                  : 'N/A' }}
                              </small>
                            </p>
                          </div>
                          <div>
                            <button type="button" (click)="viewDetailModal(item)"
                              class="d-block d-sm-none d-md-none d-lg-none btn btn-outline-dark bg-white text-capitalize rounded"
                              style="border: 1px solid #ddd" mdbRipple rippleColor="dark">
                              View Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" (click)="viewDetailModal(item)"
                  class="d-none d-sm-block d-md-block d-lg-block btn btn-outline-dark bg-white text-capitalize rounded m-2 position-absolute"
                  style="border: 1px solid #ddd; bottom: 10px; left: 10px; right: 10px;" mdbRipple rippleColor="dark">
                  View Detail
                </button>
              </div>
            </div>
          </div>
          <div class="py-3">
            <div class="alert d-flex gap-2 mb-0">
              <i class="fas fa-info-circle"></i>
              <div class="d-flex flex-column">
                <p class="m-0">Additional Providers and Services Coming Soon!</p>
                <p class="m-0"> If your provider or service isn't listed, please call <a class="text-primary text-decoration-underline" href="tel:+8552846216">(855) 284-6216</a> to find out more.</p>
              </div>
            </div>
          </div>
          <ng-template #loadingCards>
            <div class=" row g-4 px-2 py-4">
              <div class="col-12 col-sm-6 col-lg-3"
                *ngFor="let i of [1,2,3,4]">
                <app-skeleton-loader height="335px" width="100%"></app-skeleton-loader>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="d-none d-sm-block d-md-block d-lg-block"
          *ngIf="!loadingProviders && mainSearchFiltersData?.providers?.length">
          <pagination-controls class="text-center" (pageChange)="pageChanged($event)" [autoHide]="true">
          </pagination-controls>
        </div>
        <div class="py-3 text-center d-block d-sm-none d-md-none d-lg-none"
          *ngIf="!loadingProviders && mainSearchFiltersData?.providers?.length &&  mainSearchFiltersData?.providers?.length > HOLISTA_CONSTANT.defaultPaginationQuery.limit">
          <button [disabled]="listingSearchFilter?.pagination?.totalCount === loadMorePageCount" type="button"
            class="btn btn-outline-dark bg-white text-capitalize rounded-pill" style="border: 1px solid #ddd" mdbRipple
            rippleColor="dark" (click)="pageChanged(null, true)">
            Load More
          </button>
        </div>
      <!-- </div> -->
    </div>
    <!-- <div class="my-3" *ngIf="showSuggestions && loadingProviders">
      <app-loader [loader]="true"></app-loader>
    </div> -->
  </div>
</div>