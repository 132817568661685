<section class="text-center text-lg-start">
  <section class="container container-outer" style="padding: 16px" [style.padding]="isSmallScreen ? '8px' : '16px'">
    <section class="agreements-wrapper">
      <div class="d-flex align-items-center gap-1">
        <span (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_WEBSITE)">
          Holista Health
        </span>
          <div>
            <span class="text-primary cursor-pointer"
              (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_PRIVACY_POLICY)">Privacy Policy
            </span> |
            <span class="text-primary cursor-pointer"
              (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_TERMS_AND_CONDITION)">Terms & Conditions
            </span>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-center cursor-pointer"
        (click)="onLinkClick(networkConfig?.website)">
        <i class="fas fa-globe text-primary"></i>
        &nbsp; <span class="fst-normal ms-1">{{networkConfig?.website ? networkConfig.website : "N/A"}}</span>
      </div>
    </section>
  </section>
</section>
