import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() height: string | number = '12px';
  @Input() width?: string | number;
  @Input() alignment: 'start' | 'center' | 'end' = 'start';
  @Input() borderRadius: string | number;

  randomWidth: string = '';

  ngOnInit() {
    // Compute randomWidth only once when the component initializes
    this.randomWidth = `${Math.random() * 30 + 55}%`;
  }
}
