export const HOLISTA_CONSTANT = {
  NAV_ADDRESS_1: 'N92 W 14612 Anthony Avenue,',
  NAV_ADDRESS_2: 'Menomonee Falls, WI 53051',
  INFO_EMAIL: 'info@holistahealth.com',
  HOLISTA_WEBSITE: 'www.holistahealth.com',
  HOLISTA_PHONE: '414-961-6880',
  HOLISTA_ABOUT: 'https://holistahealth.com/Holista/About.htm',
  HOLISTA_CONTACT: 'https://holistahealth.com/Holista/Contact.htm',
  HOLISTA_GET_STARTED:
    'https://holista.holistahealth.com/login?redirected=true',
  HOLISTA_PRIVACY_POLICY:
    'https://holistahealth.com/Holista/OffNav/Privacy-Policy.htm',
  HOLISTA_TERMS_AND_CONDITION:
    'https://holistahealth.com/Holista/OffNav/Privacy-Policy.htm',
  GOOGLE_MAPS_URL: 'https://www.google.com/maps/dir',
  DEFAULT_LOCATION: {
    zip: 53201,
    city: 'Milwaukee',
    state: 'WI',
    st: 'WI',
    lat: 43.0113,
    lng: -87.958,
    displayName: 'Milwaukee, WI',
  },
  DEFAULT_RADIUS: 48270,
  RADIUS: [
    { label: '30 mi.', value: 48270 },
    { label: '60 mi.', value: 96540 },
    { label: '90 mi.', value: 144810 },
    { label: '120 mi.', value: 193080 },
    { label: '240 mi.', value: 386160 },
  ],
  modalConfig: {
    animation: true,
    backdrop: true,
    data: {
      title: '',
      body: '',
      data: {},
    },
    ignoreBackdropClick: true,
    keyboard: true,
    // modalClass: 'modal-lg',
  },
  owlCarouselOptions: {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    autoplay: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 3,
      },
      760: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  },
  defaultPaginationQuery: {
    offset: 0,
    limit: 25,
    totalCount: 0,
    page: 1,
  },
  ENTITY_TYPE_SECOND: 2,
  ENTITY_TYPE: {
    PHYSICIAN: 1,
    FACILITY: 2,
  },
  LAYOUT: {
    HEADER_HEIGHT: '32px',
    FOOTER_HEIGHT: '42px',
    SEARCH_CONTAINER_HEIGHT: '140px',
  },
};

export const ANNOUNCEMENT_APP = {
  GOOGLE_STORE:
    'https://play.google.com/store/apps/details?id=com.novelty.medicaid.holista',
  APPLE_STORE: 'https://apps.apple.com/us/app/holista/id6467197659',
};

export const NETWORK_CODES = 'HPS,NC';

export const BUNDLE_NAME_CONCAT_STRING = {
  HPS: '(Wisconsin)',
  NC: '(North Carolina)',
};
