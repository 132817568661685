import { Subject, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
} from 'rxjs/operators';

import { HOLISTA_CONSTANT, NETWORK_CODES } from '../../constants';
import { LocationUtils, StorageUtility } from 'src/app/utils';
import { MainService } from 'src/app/services';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit {
  mainSearchForm: FormGroup;
  searchProviderText = new Subject();
  searchBundleText = new Subject();
  searchLocationText = new Subject();
  mainSearchFormData: any;
  networkCode: string;
  bundleList: any[] = [];
  providerList: any[] = [];
  locationList: any[] = [];
  notFound = false;
  isLoading = {
    bundles: false,
    locations: false,
    providers: false,
  };
  providerSearchParams = {};
  PAGINATION_QUERY = {
    ...HOLISTA_CONSTANT.defaultPaginationQuery,
  };
  backupBundle: any;
  backupProvider: any;
  displayClearButton = {
    provider: false,
    service: false,
  };
  isSearchByProvider: boolean = false;
  isSearchClicked: boolean = false;
  radius = HOLISTA_CONSTANT.RADIUS;

  constructor(
    public modalRef: MdbModalRef<SearchModalComponent>,
    private _formBuilder: FormBuilder,
    private _mainService: MainService,
    public _locationUtils: LocationUtils,
    public _storageUtility: StorageUtility
  ) {
    this.setMainSearchForm();
    this.mainSearchFormData = this.mainSearchForm.value;
    // this.searchBundleText
    //   .pipe(
    //     map((event) => event),
    //     debounceTime(300),
    //     distinctUntilChanged((previousValue: any, currentValue: any) => {
    //       if (previousValue != currentValue) return false;
    //       return true;
    //     }),
    //     mergeMap((keyword) => of(keyword))
    //   )
    //   .subscribe((keyword) => {
    //     if (typeof this.mainSearchForm.value.bundle === 'string') {
    //       this.displayClearButton.service = keyword?.length > 2;
    //       this.backupBundle = this.mainSearchForm.value.bundle;
    //       this.mainSearchForm.controls['optionClicked'].setValue(
    //         typeof this.mainSearchForm.value.bundle === 'string' ? false : true
    //       );
    //     }
    //     if (
    //       keyword?.length > 2 ||
    //       keyword?.displayName ||
    //       keyword?.bundleName
    //     ) {
    //       this.fetchBundle(
    //         typeof keyword === 'string'
    //           ? keyword
    //           : keyword?.displayName || keyword?.bundleName
    //       );
    //     } else if (!keyword?.length) {
    //       this.bundleList.length = 0;
    //       delete this.providerSearchParams['serviceId'];
    //     }
    //   });

    // this.searchProviderText
    //   .pipe(
    //     map((event) => event),
    //     debounceTime(300),
    //     distinctUntilChanged((previousValue: any, currentValue: any) => {
    //       if (previousValue != currentValue) return false;
    //       return true;
    //     }),
    //     mergeMap((keyword) => of(keyword))
    //   )
    //   .subscribe((keyword) => {
    //     if (typeof this.mainSearchForm.value.provider === 'string') {
    //       this.displayClearButton.provider = keyword?.length > 2;
    //       this.backupProvider = this.mainSearchForm.value.provider;
    //       this.mainSearchForm.controls['optionClicked'].setValue(
    //         typeof this.mainSearchForm.value.provider === 'string'
    //           ? false
    //           : true
    //       );
    //     }
    //     if (keyword?.length > 2 || keyword?.displayName || keyword?.provider) {
    //       this.fetchProvider(
    //         typeof keyword === 'string'
    //           ? keyword
    //           : keyword?.displayName || keyword?.provider
    //       );
    //     } else if (!keyword?.length) {
    //       this.bundleList.length = 0;
    //       delete this.providerSearchParams['providerId'];
    //     }
    //   });

    this.searchLocationText
      .pipe(
        map((event) => event),
        debounceTime(300),
        distinctUntilChanged((previousValue: any, currentValue: any) => {
          if (previousValue != currentValue) return false;
          return true;
        }),
        mergeMap((keyword) => of(keyword))
      )
      .subscribe((keyword) => {
        if (keyword?.length > 2 || keyword?.value) {
          this.fetchLocations(keyword);
        } else if (!keyword?.length) {
          delete this.providerSearchParams['longitude'];
          delete this.providerSearchParams['latitude'];
          delete this.providerSearchParams['radius'];
          this.fetchLocations();
        }
      });
  }

  ngOnInit(): void {
    this.networkCode =
      this._storageUtility.get('local', 'networkCode') || NETWORK_CODES;
    this.isSearchByProvider = this.mainSearchFormData.searchBy === 'provider';
    if (this.isSearchByProvider) {
      this.alterFormValidationOnProviderSelect();
    }
    this.mainSearchForm.patchValue(this.mainSearchFormData);
  }

  setMainSearchForm() {
    this.mainSearchForm = this._formBuilder.group({
      searchBy: [null],
      provider: [null],
      bundle: [null, Validators.required],
      location: [null, Validators.required],
      searchClicked: [false],
      optionClicked: [false],
      providers: [null],
      physicians: [null],
      radius: [null],
      physicianId: [null],
      pagination: [null],
      networkCode: [],
    });
  }

  onBundleDisplay = (bundle?): string | any => {
    if (typeof bundle === 'string') {
      return bundle;
    }
    return bundle
      ? bundle.label || bundle.displayName || bundle.bundleName || undefined
      : undefined;
  };

  onProviderDisplay = (provider?): string | any => {
    if (typeof provider === 'string') {
      return provider;
    }
    return provider
      ? provider.label ||
          provider.displayName ||
          provider.businessName ||
          undefined
      : undefined;
  };

  onLocationDisplay = (location?): string | undefined => {
    if (typeof location === 'string') {
      return location;
    }
    return location ? location.label : undefined;
  };

  onBundleOptionSelected(option: any) {
    if (this.isSearchByProvider) {
      this.displayClearButton.provider = true;
      const { id, entityType, isChipSelected } = option;
      this.providerSearchParams[
        entityType === 1 ? 'physicianId' : 'facilityId'
      ] = id;
      delete this.providerSearchParams['serviceId'];
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(
        HOLISTA_CONSTANT.defaultPaginationQuery
      );
      if (isChipSelected) {
        this.mainSearchForm.controls['provider'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onProviderDisplay(option);
      }
    } else {
      this.displayClearButton.provider = true;
      const { serviceBundleId, isChipSelected } = option;
      this.providerSearchParams['serviceId'] = serviceBundleId;
      delete this.providerSearchParams['physicianId'];
      delete this.providerSearchParams['facilityId'];
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(
        HOLISTA_CONSTANT.defaultPaginationQuery
      );
      if (isChipSelected) {
        this.mainSearchForm.controls['bundle'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onBundleDisplay(option);
      }
    }
  }

  onLocationOptionSelected(option: any) {
    const { lng, lat } = option;
    this.providerSearchParams['latitude'] = lat;
    this.providerSearchParams['longitude'] = lng;
    this.mainSearchForm.controls['radius'].setValue(
      HOLISTA_CONSTANT.DEFAULT_RADIUS
    );
    this.providerSearchParams['radius'] = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  }

  fetchProvider(keyword: string) {
    // this.isLoading.providers = true;
    this.providerList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getProviders({ keyword, networks: this.networkCode })
      .pipe(
        finalize(() => {
          // this.isLoading.bundles = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { data, success } = response;
          if (success) {
            if (data?.length) {
              this.providerList = data.map((data: any) => ({
                displayName: data?.provider.displayName,
                businessName: data?.provider.businessName,
                id: data?.provider.id,
                label:
                  data?.provider.displayName || data?.provider.businessName,
                value: data?.provider.id,
                entityType: data?.provider.entityType,
              }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting bundles', error);
        },
      });
  }

  fetchBundle(keyword: string) {
    this.isLoading.bundles = true;
    this.bundleList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getBundles({ keyword, networks: this.networkCode })
      .pipe(
        finalize(() => {
          this.isLoading.bundles = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { data, success } = response;
          if (success) {
            if (data?.rows?.length) {
              const allBundleDisplayNames = data.rows.map(
                (bundle) => bundle.displayName
              );
              const uniqueBundlesByDisplayName = [
                ...new Set(allBundleDisplayNames),
              ];
              this.bundleList = [];
              uniqueBundlesByDisplayName.forEach((uniqueBundle) => {
                const bundles = data.rows.filter(
                  (data) => data.displayName === uniqueBundle
                );
                this.bundleList.push({
                  displayName: bundles[0]?.displayName,
                  bundleName: bundles[0]?.bundleName,
                  // serviceBundleId: bundles[0]?.serviceBundleId,
                  serviceBundleId: bundles
                    .map((bundle) => bundle.serviceBundleId)
                    .join(','),
                  label: bundles[0]?.displayName || bundles[0]?.bundleName,
                  value: bundles[0]?.bundleCode,
                });
              });
              // this.bundleList = data.rows.map((bundle: any) => ({
              //   displayName: bundle?.displayName,
              //   bundleName: bundle?.bundleName,
              //   serviceBundleId: bundle?.serviceBundleId,
              //   label: `${bundle?.displayName || bundle?.bundleName} ${BUNDLE_NAME_CONCAT_STRING[bundle?.network?.code]}`,
              //   value: bundle?.bundleCode,
              // }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting bundles', error);
        },
      });
  }

  clearAutocompleteSearch() {
    if (!this.isSearchByProvider) {
      this.searchBundleText.next('');
      this.mainSearchForm.controls['bundle'].setValue(null);
      this.displayClearButton.service = false;
    } else {
      this.searchProviderText.next('');
      this.mainSearchForm.controls['provider'].setValue(null);
      this.displayClearButton.provider = false;
    }
  }

  fetchLocations(keyword?: string) {
    this.isLoading.locations = true;
    this.locationList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getLocations({ ...(keyword && { keyword }) })
      .pipe(
        finalize(() => {
          this.isLoading.locations = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { success, data } = response;
          if (success) {
            if (data?.length) {
              this.locationList = response.data.map((location: any) => ({
                ...location,
                label: `${location.city}, ${location.st}`,
                value: location.city,
              }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting locations', error);
        },
      });
  }

  handleSelectCurrentLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const loc: any = await this._locationUtils.getLocationDetails(
            position
          );
          if (loc) {
            this.providerSearchParams['latitude'] = loc.lat;
            this.providerSearchParams['longitude'] = loc.lng;
            this.mainSearchForm.controls['location'].setValue({
              city: loc.city,
              label: loc.displayName,
              lat: loc.lat,
              lng: loc.lng,
              st: loc.state,
              value: loc.city,
              zip: loc.zip,
            });
            this.mainSearchForm.controls['radius'].setValue(
              HOLISTA_CONSTANT.DEFAULT_RADIUS
            );
            this.providerSearchParams['radius'] =
              HOLISTA_CONSTANT.DEFAULT_RADIUS;
          }
        },
        (error) => console.log(error.message)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  closeSearchModal(isClose): void {
    console.log({ isClose });
    if (isClose) {
      this.modalRef.close();
      this.isSearchClicked = false;
    } else {
      this.isSearchClicked = true;
      if (this.mainSearchForm.valid) {
        this.modalRef.close({
          mainSearchFormData: this.mainSearchForm.value,
          providerSearchParams: this.providerSearchParams,
        });
        this.isSearchClicked = false;
      }
    }
  }

  alterFormValidationOnProviderSelect() {
    this.isSearchByProvider = true;
    this.mainSearchForm.controls['bundle'].setValue(null);
    this.mainSearchForm.get('provider').setValidators([Validators.required]);
    this.mainSearchForm.get('bundle').clearValidators();
  }

  changeSearchBy(event) {
    this.isSearchClicked = false;
    if (event.target.value === 'provider') {
      this.alterFormValidationOnProviderSelect();
    } else {
      this.isSearchByProvider = false;
      this.mainSearchForm.controls['provider'].setValue(null);
      this.mainSearchForm.get('bundle').setValidators([Validators.required]);
      this.mainSearchForm.get('provider').clearValidators();
    }
    this.mainSearchForm.get('provider').updateValueAndValidity();
    this.mainSearchForm.get('bundle').updateValueAndValidity();
    this.clearAutocompleteSearch();
  }
}
