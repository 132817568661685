<section class="text-center text-lg-start">
  <section class="container container-outer" style="padding: 16px 8px">
    <section class="agreements-wrapper">
      <div class="d-flex align-items-center agreements-wrapper-about">
        <span (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_WEBSITE)">
          © {{ year }} Holista Health
        </span>
        <div>
          <span class="text-primary cursor-pointer"
            (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_PRIVACY_POLICY)">Privacy Policy
          </span> |
          <span class="text-primary cursor-pointer"
            (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_TERMS_AND_CONDITION)">Terms & Conditions
          </span>
        </div>
        <span>Version {{version}}</span>
      </div>
      <div
        class="d-flex align-items-center justify-content-center cursor-pointer"
        (click)="onLinkClick(networkConfig?.website)">
        <i class="fas fa-globe text-primary"></i>
        &nbsp; <span class="fst-normal ms-1">{{networkConfig?.website ? networkConfig.website : "https://www.holistahealth.com/"}}</span>
      </div>
    </section>
  </section>
</section>
