<div class="container-fluid p-0 position-relative">
  <div class="search-main position-relative d-flex flex-column" [style.min-height]="isFormSubmitted ? searchListingHeight + 'px' : 'auto'">
    <div class="main-search" 
      [ngClass]="{
        'pt-4': !mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked && !isSmallScreen,
        'pt-2': isSmallScreen,
        'main-search-sticky': isFormSubmitted && isHeaderFixed,
      }"
      >
      <div class="container px-2">
        <div class="mx-auto w-100"
          [class.mt-2]="isSmallScreen"
          *ngIf="!mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked">
          <h1>Find a Provider</h1>
        </div>
      </div>
      <div class="container px-2">
        <div class="d-flex justify-content-between align-items-center bg-white px-3 py-2 cursor-pointer"
          style="border-radius: 8px;" (click)="openSearchModal()"
          *ngIf="(mainSearchFormData.searchClicked || mainSearchFormData.optionClicked) && isSmallScreen; else searchForm">
          <div>
            <p class="fs-6 m-0">
              {{
              mainSearchFormData?.bundle?.displayName || mainSearchFormData?.bundle?.bundleName ||
              mainSearchFormData?.provider?.displayName || mainSearchFormData?.provider?.businessName ||
              mainSearchFormData?.bundle ||
              mainSearchFormData?.provider
              }}
            </p>
            <p class="m-0 small text-muted">
              {{ mainSearchFormData?.location?.label || 'Enter Location' }}
            </p>
          </div>
          <i class="fas fa-search"></i>
        </div>
        <ng-template #searchForm>
          <form class="mx-auto w-100 m-2" [formGroup]="mainSearchForm">
            <div #searchTypeSelection class="d-flex justify-content-between gap-2" style="margin-bottom: 16px" [ngClass]="{
              'flex-column': isSmallScreen,
              'align-items-center': !isSmallScreen,
              'd-none': isSearchTypeSelectionHidden
            }">
              <h6 class="m-0" [ngClass]="{'mt-1': isSmallScreen}">Find a doctor and facility for your medical service</h6>
              <div class="d-flex justify-content-between">
                <div class="form-check form-check-inline p-0">
                  <p class="h6 m-0 p-0">Search by</p>
                </div>
                <div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="searchBy" id="provider1" value="provider"
                      formControlName="searchBy" (change)="changeSearchBy($event)" />
                    <label class="form-check-label" for="provider1">Provider</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="searchBy" id="service1" value="service"
                      formControlName="searchBy" (change)="changeSearchBy($event)" />
                    <label class="form-check-label" for="service1">Service</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row bg-white g-1 p-2 custom-rounded" style="box-shadow: 0px 8px 10px 0px #cbcbcb">
              <!-- Provider Auto Complete Starts -->
              <div class="bundle col-12 col-sm-4 col-md-5 col-lg-5 m-0 pe-2" *ngIf="isSearchByProvider">
                <mdb-form-control [ngClass]="{'input-field-error': isSearchClicked && mainSearchForm.get('searchBy').value !== 'service' && mainSearchForm.get('provider').invalid}">
                  <i class="icon fas fa-magnifying-glass text-gray" style="padding-top: 2px"></i>
                  <input mdbInput formControlName="provider" (ngModelChange)="searchProviderText.next($event)"
                    type="text" id="autocompleteProvider"
                    class="form-control m-0" placeholder="Search for Physicians" />
                  <div *ngIf="displayClearButton.provider" class="autocomplete-loader cursor-pointer" role="status"
                    (click)="clearAutocompleteSearch()">
                    <i class="fa-solid fa-xmark text-primary"></i>
                  </div>
                </mdb-form-control>
              </div>
              <!-- Provider Auto Complete Ends -->
              <!-- Service Auto Complete Starts -->
              <div class="bundle col-12 col-sm-4 col-md-5 col-lg-5 m-0" [class.pe-2]="!isSmallScreen" *ngIf="!isSearchByProvider">
                <mdb-form-control [ngClass]="{'input-field-error':  isSearchClicked && mainSearchForm.get('searchBy').value === 'service' && mainSearchForm.get('bundle').invalid}">
                  <i class="icon fas fa-magnifying-glass text-gray" style="padding-top: 2px"></i>
                  <input mdbInput formControlName="bundle" (ngModelChange)="searchBundleText.next($event)"
                    [mdbAutocomplete]="autocompleteBundle" type="text" id="autocompleteBundle"
                    class="form-control m-0" placeholder="Search for Services" />
                  <div *ngIf="displayClearButton.service" class="autocomplete-loader cursor-pointer" role="status"
                    (click)="clearAutocompleteSearch()">
                    <i class="fa-solid fa-xmark text-primary"></i>
                  </div>
                </mdb-form-control>
                <mdb-autocomplete #autocompleteBundle="mdbAutocomplete" [displayValue]="onBundleDisplay">
                  <!-- <mdb-option *ngFor="let option of bundleList" [value]="option"
                    (click)="onBundleOptionSelected(option)">
                    {{ option.label }}
                  </mdb-option>
                  <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
                  <div *ngIf="isLoading.bundles" class="autocomplete-no-results"><small>Loading...</small></div> -->
                </mdb-autocomplete>
              </div>
              <!-- Service Auto Complete Ends -->
              <!-- Location Auto Complete Starts -->
              <div class="location col-12 col-sm-3 col-md-3 col-lg-3 m-0" [ngClass]="{'border-start px-2': !isSmallScreen}">
                <mdb-form-control [ngClass]="{'input-field-error':  isSearchClicked && mainSearchForm.get('location').invalid}">
                  <i class="icon fas fa-location-dot text-gray"></i>
                  <input mdbInput formControlName="location" (ngModelChange)="searchLocationText.next($event)" (input)="onInputChange($event)"
                    [mdbAutocomplete]="autocompleteLocation" type="text" id="autocompleteLocation"
                    class="form-control m-0" placeholder="Enter Location*" />
                  <button mdbRipple class="icon-right btn btn-white btn-floating bg-white"
                    mdbTooltip="Use current location" (click)="handleSelectCurrentLocationClick()">
                    <i class="fa-solid fa-location-crosshairs text-primary fa-lg"></i>
                  </button>
                </mdb-form-control>
                <mdb-autocomplete #autocompleteLocation="mdbAutocomplete" [displayValue]="onLocationDisplay">
                  <mdb-option *ngFor="let option of locationList" [value]="option"
                    (click)="onLocationOptionSelected(option)">
                    {{ option.label }}
                  </mdb-option>
                  <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
                  <div *ngIf="isLoading.locations" class="autocomplete-no-results"><small>Loading...</small></div>
                </mdb-autocomplete>
              </div>
              <!-- Location Auto Complete Ends -->
              <!-- Radius Select Starts -->
              <div class="radius col-12 col-sm-3 col-md-2 col-lg-2 m-0" [ngClass]="isSmallScreen ? 'pt-2' : 'border-start ps-2'">
                <div class="d-flex justify-content-between align-items-center small text-muted m-0">
                  <mdb-form-control class="w-100">
                    <i class="icon fas fa-street-view text-gray fa-lg" style="padding-top: 8px"></i>
                    <mdb-select formControlName="radius" placeholder="Distance"
                      style="width: auto;">
                      <mdb-option *ngFor="let option of radius" [value]="option.value" (click)="onRadiusSelected($event)">
                        {{option.label}}
                      </mdb-option>
                    </mdb-select>
                  </mdb-form-control>
                </div>
              </div>
              <!-- Radius Select Ends -->
              <div class="search col-12 col-sm-2 col-md-2 col-lg-2 m-0">
                <button mdbRipple type="button"
                  class="d-flex align-items-center justify-content-center btn btn-primary w-100 rounded ripple-surface"
                  (click)="submitMainFilter()">
                  <!-- [class.disabled]="mainSearchForm.invalid" (click)="submitMainFilter()"> -->
                  <i class="fas fa-search"></i>&nbsp;
                  <small class="text-capitalize fw-normal fs-6">
                    Search
                  </small>
                </button>
              </div>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
    <div *ngIf="!isSearchByProvider && categoryData.length">
    <!-- <div class="container d-flex align-items-center justify-content-center gap-2" *ngIf="!selectedCategory; else categorizedView"> -->
      <div class="container d-flex justify-content-center align-items-center gap-2">
        <button
          [class.disabled]="isCategorySliderAtStart"
          type="button"
          class="btn btn-outline btn-rounded btn-sm bg-white"
          mdbRipple rippleColor="dark"
          (click)="onSlideCategories({ direction: 'left' })"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <div #chipContainer class="d-flex align-items-center gap-2 chip-container" style="margin-top: 2px;">
          <div class="chip chip-sm chip-outline btn-outline-secondary d-inline-block m-0"
            *ngFor="let data of categoryData"
            [attr.data-category]="data.category.toLowerCase()"
            [class.active]="selectedCategory?.category?.toLowerCase() === data.category?.toLowerCase()"
            (click)="onServiceCategorySelect({ data })">
            <p class="m-0"><i *ngIf="selectedCategory?.category?.toLowerCase() === data.category?.toLowerCase()" class="fas fa-circle-check text-primary me-1"></i>{{ data.category }}</p>
          </div>
        </div>
        <button
          [class.disabled]="isCategorySliderAtEnd"
          type="button"
          class="btn btn-rounded btn-sm bg-white"
          mdbRipple rippleColor="dark"
          (click)="onSlideCategories({ direction: 'right' })"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
      <ng-template #categorizedView>
        <!-- <div class="chip-container container d-flex gap-2">
          <div class="d-flex align-items-center gap-2">
            <div class="dropdown">
              <button
                class="btn dropdown-toggle selected-category"
                type="button"
                id="dropdownMenuButton"
                aria-expanded="false"
                (click)="onShowCategoriesDropdown({ data: selectedCategory })"
              >
                {{ selectedCategory?.category }}
              </button>
              
              <ul 
                *ngIf="categoriesDropdownList.length" 
                class="dropdown-menu" 
                aria-labelledby="dropdownMenuButton"
                [ngClass]="{'show': categoriesDropdownList.length}"
              >
                <li *ngFor="let data of categoriesDropdownList">
                  <span class="dropdown-item" (click)="onServiceCategorySelect({ data, isDropdownSelection: true })">{{ data.category }}</span>
                </li>
              </ul>
            </div>
            <i *ngIf="selectedCategory?.subCategories?.length" class="fas fa-chevron-right" style="font-size: 12px"></i>
          </div>
          <div #chipContainer class="d-flex flex-column gap-2">
            <div class="d-flex gap-2 align-items-center">
              <p>Sub categories goes here...</p>
            </div>
          </div>
        </div> -->
      </ng-template>
    </div>
    <!-- <div *ngIf="!mainSearchFormData.searchClicked && !mainSearchFormData.optionClicked">
      <p class="text-center mt-3 px-2" style="font-weight: bolder;">Additional Providers and Services Coming Soon! <br>
        If your provider or service isn't listed, please call <a class="text-primary text-decoration-underline" href="tel:+8552846216">(855) 284-6216</a> to find out more.
      </p>
    </div> -->

    <!--TODO: when dynamic service categories are present-->
    <!-- <div *ngIf="!mainSearchFormData.searchClicked" class="container d-flex flex-column">
      <p>Categories of Services Available</p>
      <div class="d-flex gap-3">
        <div class="service-category bg-white d-flex flex-column align-items-center justify-content-center gap-1" (click)="onServiceCategorySelect('gastroenterology')">
          <i class="fas fa-atom text-primary" style="font-size: 24px;"></i>
          <p class="m-0">Gastroenterology</p>
        </div>
        <div class="service-category bg-white d-flex flex-column align-items-center justify-content-center gap-1" (click)="onServiceCategorySelect('orthopedic')">
          <i class="fas fa-bone text-primary" style="font-size: 24px;"></i>
          <p class="m-0">Orthopedic</p></div>
      </div>
    </div> -->
    <!--TODO: when dynamic service categories are present-->

    <!-- <div *ngIf="showCategoriesAndAppAnnouncement" class="bg-white p-4 d-flex flex-column gap-2">
      <div class="d-flex flex-column align-items-center">
        <p>Categories of Services Available</p>
        <div class="d-flex gap-2">
          <div class="service-category d-flex flex-column align-items-center justify-content-center" (click)="onServiceCategorySelect('gastroenterology')">
            <i class="fas fa-bone text-primary"></i>
            <p class="m-0">Gastroenterology</p>
          </div>
          <div class="service-category d-flex flex-column align-items-center justify-content-center" (click)="onServiceCategorySelect('orthopedic')">
            <i class="fas fa-bone text-primary"></i>
            <p class="m-0">Orthopedic</p></div>
        </div>
      </div>
      <div class="app-info">
        <div class="app-info__title" >
            <div class="app-info__title__main"> 
              <h4 class="main_title">Download</h4>
              <h3 class="main_title"><b> OUR NEW</b></h3>
              <h4 class="main_title"> APP!</h4>
            </div>
            <div class="app_info__section">
              <div class="app-info__text">
                Download the NEW Holista app to start an Episode or manage your existing Episode.
                <p>Request information, manage your tasks and chat with a Care Coordinator all from your Smartphone.</p>
              </div>
            </div>

            <div class="app-info__qr">
              <div>
                <img [src]="androidQr" alt="" class="me-4 android_qr" width="auto" height="90" /> <br>
                <a [href]="googlePlayLink" target="_blank">
                  <img [src]="googlePlayIcon" alt="" class="me-4 google_play_icon" width="auto" height="40" />
                </a>
              </div>
              <div>
                <img [src]="appleQr" alt="" class="me-4 apple_qr" width="auto" height="90" /> <br>
                <a [href]="appleStoreLink" target="_blank">
                  <img  [src]="applePlayIcon" alt="" class="me-4 apple-store apple_play_icon" width="auto" height="40" />
                </a>
              </div>
            </div>
            </div>
      </div>
    </div> -->
    <!-- <div *ngIf="showCategoriesAndAppAnnouncement" class="download-container bg-white d-flex justify-content-center align-items-center" [style.margin-top]="!isSmallScreen ? HOLISTA_CONSTANT.LAYOUT.SEARCH_CONTAINER_HEIGHT : ''"> -->
    <div *ngIf="showCategoriesAndAppAnnouncement" class="download-container bg-white d-flex justify-content-center align-items-center" [class.mt-4]="!isSmallScreen" [class.mt-3]="isSmallScreen && !isSearchByProvider">
      <div class="container position-relative">
        <div class="text-container py-4 d-flex flex-column justify-content-center gap-2">
          <h2 class="m-0">Download our NEW APP!</h2>
          <small>
            Download the NEW Holista app to start an Episode or manage your existing Episode.
            Request information, manage your tasks and chat with a Care Coordinator all from your Smartphone.
          </small>
    
          <div class="qr-container d-flex gap-2">
            <div class="qr-code-container d-flex flex-column gap-2">
              <img [src]="androidQr" alt="Google Play QR Code" />
              <a [href]="googlePlayLink" target="_blank">
                <img [src]="googlePlayIcon" alt="Get it on Google Play" />
              </a>
            </div>
            <div class="qr-code-container d-flex flex-column justify-content-between">
              <img [src]="appleQr" alt="App Store QR Code" />
              <a [href]="appleStoreLink" target="_blank">
                <img [src]="applePlayIcon" alt="Download on the App Store" />
              </a>
            </div>
          </div>
        </div>
        <div class="app-preview">
          <img [src]="appPreview" alt="App Preview" />
        </div>
      </div>
    </div>
  </div>
  <div #searchListing 
    *ngIf="mainSearchFormData.searchClicked || mainSearchFormData.optionClicked" 
    class="search-list" 
    [class.search-list--provider]="isSearchByProvider" 
    [style.top]="isSearchByProvider 
                  ? isSmallScreen 
                    ? '74px' 
                    : '138px' 
                  : isSmallScreen 
                    ? '118px' 
                    : '176px'"
    >
    <app-search-listing 
      [mainSearchFilters]="mainSearchFormData"
      (updateMainSearchFilter)="updateMainSearchFilter($event)"
      (onBundleOptionSelected)="onBundleOptionSelected($event)"
      (onViewChange)="onViewChange()"
      (onLocationOptionSelected)="onLocationOptionSelected($event)" [isSmallScreen]="isSmallScreen"
      [loadingProviders]="isLoading.providers" 
      [isSearchByProvider]="isSearchByProvider"
      [isFormSubmitted]="isFormSubmitted"
    >
    </app-search-listing>
  </div>
</div>