import { Subject, Subscription, of } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
} from 'rxjs/operators';
import { MainService } from '../../services';
import {
  ANNOUNCEMENT_APP,
  HOLISTA_CONSTANT,
  NETWORK_CODES,
} from '../../constants';
import { LocationUtils, StorageUtility, HolistaUtils } from 'src/app/utils';
import { SearchModalComponent } from 'src/app/modals';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ICategory } from 'src/app/interfaces';
const appleQr = '../../../assets/img/jpg/ios-qr.jpeg';
const androidQr = '../../../assets/img/png/android-qr.png';
const googlePlayIcon = '../../../assets/img/png/google-play-badge.png';
const applePlayIcon = '../../../assets/img/png/apple-store-badge.png';
const appPreview = '../../../assets/img/png/app-preview.png';

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss'],
})
export class MainBodyComponent implements OnInit {
  mainSearchForm: FormGroup;
  searchBundleText = new Subject();
  searchProviderText = new Subject();
  searchLocationText = new Subject();
  modalRef: MdbModalRef<SearchModalComponent> | null = null;
  notFound = false;
  mainSearchFormData: any;
  networkCode: string;
  options = [
    { value: '1', label: 'Provider' },
    { value: '2', label: 'Facility' },
  ];
  bundleList: any[] = [];
  providerList: any[] = [];
  locationList: any[] = [];
  showCategoriesAndAppAnnouncement = true;
  isLoading = {
    bundles: false,
    locations: false,
    providers: false,
    categories: false,
  };
  providerSearchParams = {};
  googlePlayLink = ANNOUNCEMENT_APP.GOOGLE_STORE;
  appleStoreLink = ANNOUNCEMENT_APP.APPLE_STORE;
  DEFAULT_RADIUS = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  PAGINATION_QUERY = {
    ...HOLISTA_CONSTANT.defaultPaginationQuery,
  };
  backupBundle: any;
  appleQr = appleQr;
  androidQr = androidQr;
  googlePlayIcon = googlePlayIcon;
  applePlayIcon = applePlayIcon;
  appPreview = appPreview;
  backupProvider: any;
  isSmallScreen: boolean = false;
  isLargeScreen: boolean = false;
  displayClearButton = {
    provider: false,
    service: false,
  };
  isSearchByProvider: boolean = false;
  isSearchClicked: boolean = false;
  radius = HOLISTA_CONSTANT.RADIUS;
  isFormSubmitted: boolean = false;
  HOLISTA_CONSTANT = this._holistaUtility.deepClone(HOLISTA_CONSTANT);
  isLocationInputChanged: boolean = false;
  isHeaderFixed = false; // Controls fixed positioning
  lastScrollY = 0;
  categoryData = [];
  selectedCategory: ICategory;
  selectedSubCategory: string;
  // displayedCategories: ICategory[] = [...this.categoryData];
  // tempDisplayedCategories: ICategory[] = [...this.categoryData];
  // hiddenChips: ICategory[] | string[] = [];
  // displayedSubCategories: string[] = [];
  // tempDisplayedSubCategories: string[] = [];
  // showHiddenChips: boolean = false;
  // chipContainerHeight: any;
  categoriesDropdownList: ICategory[] = [];
  isCategorySliderAtStart: boolean = true;
  isCategorySliderAtEnd: boolean = false;
  locationSubscription: Subscription;
  isOverflowing: boolean = false;

  @ViewChild('chipContainer', { static: false }) chipContainer!: ElementRef;

  @Output() isMainPage = new EventEmitter<any>();
  @Output() onViewportHeightChange = new EventEmitter<any>();

  constructor(
    private _formBuilder: FormBuilder,
    private _mainService: MainService,
    private _locationUtils: LocationUtils,
    private _storageUtility: StorageUtility,
    private _holistaUtility: HolistaUtils,
    private modalService: MdbModalService,
    private _cdr: ChangeDetectorRef
  ) {
    this.handleSelectCurrentLocationClick();
    this.setMainSearchForm();
    this.mainSearchFormData = this.mainSearchForm.value;
    // this.searchBundleText
    //   .pipe(
    //     map((event) => event),
    //     debounceTime(300),
    //     distinctUntilChanged((previousValue: any, currentValue: any) => {
    //       if (previousValue != currentValue) return false;
    //       return true;
    //     }),
    //     mergeMap((keyword) => of(keyword))
    //   )
    //   .subscribe((keyword) => {
    //     if (typeof this.mainSearchForm.value.bundle === 'string') {
    //       this.displayClearButton.service = keyword?.length > 2;
    //       this.backupBundle = this.mainSearchForm.value.bundle;
    //       this.mainSearchForm.controls['optionClicked'].setValue(
    //         typeof this.mainSearchForm.value.bundle === 'string' ? false : true
    //       );
    //     }
    //     if (
    //       keyword?.length > 2 ||
    //       keyword?.displayName ||
    //       keyword?.bundleName
    //     ) {
    //       this.fetchBundle(
    //         typeof keyword === 'string'
    //           ? keyword
    //           : keyword?.displayName || keyword?.bundleName
    //       );
    //     } else if (!keyword?.length) {
    //       this.bundleList.length = 0;
    //       // delete this.providerSearchParams['serviceId'];
    //     }
    //   });

    // this.searchProviderText
    //   .pipe(
    //     map((event) => event),
    //     debounceTime(300),
    //     distinctUntilChanged((previousValue: any, currentValue: any) => {
    //       if (previousValue != currentValue) return false;
    //       return true;
    //     }),
    //     mergeMap((keyword) => of(keyword))
    //   )
    //   .subscribe((keyword) => {
    //     if (typeof this.mainSearchForm.value.provider === 'string') {
    //       this.displayClearButton.provider = keyword?.length > 2;
    //       this.backupProvider = this.mainSearchForm.value.provider;
    //       this.mainSearchForm.controls['optionClicked'].setValue(
    //         typeof this.mainSearchForm.value.provider === 'string'
    //           ? false
    //           : true
    //       );
    //     }
    //     if (keyword?.length > 2 || keyword?.displayName || keyword?.provider) {
    //       this.fetchProvider(
    //         typeof keyword === 'string'
    //           ? keyword
    //           : keyword?.displayName || keyword?.provider
    //       );
    //     } else if (!keyword?.length) {
    //       this.bundleList.length = 0;
    //       delete this.providerSearchParams['providerId'];
    //     }
    //   });

    this.searchLocationText
      .pipe(
        filter((value) => typeof value === 'string'),
        map((event) => event),
        debounceTime(300),
        distinctUntilChanged((previousValue: any, currentValue: any) => {
          if (previousValue != currentValue) return false;
          return true;
        }),
        mergeMap((keyword) => of(keyword))
      )
      .subscribe((keyword) => {
        if (keyword?.length > 2 || keyword?.value) {
          this.fetchLocations(keyword);
        } else if (!keyword?.length) {
          delete this.providerSearchParams['longitude'];
          delete this.providerSearchParams['latitude'];
          delete this.providerSearchParams['radius'];
          this.fetchLocations();
        }
      });
  }

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 676;
    this.isLargeScreen = window.innerWidth > 1280;

    // TODO: If no specific network is required
    // this.networkCode =
    //   this._storageUtility.get('local', 'networkCode') || NETWORK_CODES;

    // this.networkCode = this.mainSearchForm.get('searchByState').value;

    this.locationSubscription = this.mainSearchForm.controls[
      'location'
    ].valueChanges.subscribe((value) => {
      if (typeof value !== 'string') {
        this.onLocationOptionSelected(value);
      }
    });
    this.fetchLocations();
    this.setDefaultRadius();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isSmallScreen = window.innerWidth <= 676;
    if (window.innerWidth > 575 && this.modalRef) {
      this.modalRef.close();
    }
  }

  @ViewChild('searchListing') searchListing!: ElementRef;
  searchListingHeight: number = 0;

  ngAfterViewInit() {
    this.calculateHeight();
    this._cdr.detectChanges();
    // window.addEventListener('resize', () => this.adjustDisplayedChips());
  }

  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  // Check if the chip container content is overflowing horizontally
  checkOverflow() {
    const container = this.chipContainer?.nativeElement;
    if (container) {
      this.isOverflowing = container.scrollWidth > container.clientWidth;
    }
  }

  private calculateHeight() {
    if (this.searchListing?.nativeElement) {
      // Get the height of the element
      this.searchListingHeight = this.searchListing.nativeElement.offsetHeight;
      let extraPixels = 0;

      if (this.isSearchByProvider) {
        extraPixels = this.isSmallScreen ? 320 : 252;
      } else {
        extraPixels = this.isSmallScreen
          ? this.categoryData?.length || this.isLoading.categories
            ? 352
            : 316
          : this.categoryData?.length || this.isLoading.categories
          ? 290
          : 252;
      }

      this.onViewportHeightChange.emit(
        `${+this.searchListingHeight + extraPixels}px`
      );
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollY = window.scrollY || document.documentElement.scrollTop;
    this.isHeaderFixed = this.isSmallScreen
      ? currentScrollY > 50
      : currentScrollY > 100;
    this._cdr.detectChanges(); // Force change detection
  }

  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === 'Enter' && this.mainSearchForm.valid) {
  //     this.networkCode =
  //       this._storageUtility.get('local', 'networkCode') || NETWORK_CODES;
  //     this.mainSearchForm.controls['searchClicked'].setValue(true);
  //     this.mainSearchForm.controls['networkCode'].setValue(this.networkCode);
  //     this.mainSearchFormData = this.mainSearchForm.value;
  //     this.searchProviders();
  //   }
  // }

  // getChipContainerHeight() {
  //   if (this.chipContainer) {
  //     setTimeout(() => {
  //       this.chipContainerHeight =
  //         this.chipContainer.nativeElement.offsetHeight;
  //     }, 0);
  //   }
  // }

  onChipContainerScroll() {
    const container = this.chipContainer.nativeElement;
    this.isCategorySliderAtStart = container.scrollLeft === 0;
    this.isCategorySliderAtEnd =
      container.scrollLeft + container.clientWidth >= container.scrollWidth - 1;
  }

  getCategories() {
    this.isOverflowing = false;
    this.isLoading.categories = true;
    this._mainService
      .getCategories({
        latitude: this.providerSearchParams['latitude'],
        longitude: this.providerSearchParams['longitude'],
        radius: this.mainSearchForm.get('radius').value,
        networks: this.networkCode,
      })
      .pipe(
        finalize(() => {
          this.isLoading.categories = false;
          this.calculateHeight();
        })
      )
      .subscribe({
        next: (response) => {
          const { success, data } = response;
          if (success) {
            this.categoryData = data;
            this.getCategorizedActiveServices();
            setTimeout(() => {
              this.checkOverflow();
              this.onScrollToElement(
                this.mainSearchForm.get('bundle').value?.toLowerCase()
              );
            }, 0);

            // The timeout is set to allow a brief delay for the view container to initialize
            // setTimeout(() => {
            //   this.adjustDisplayedChips();
            // }, 0);
          }
        },
        error: (error) => {
          console.log('Error getting categories', error);
        },
      });
  }

  clearAutocompleteSearch() {
    if (!this.isSearchByProvider) {
      this.searchBundleText.next('');
      this.mainSearchForm.controls['bundle'].setValue(null);
      this.displayClearButton.service = false;
    } else {
      this.searchProviderText.next('');
      this.mainSearchForm.controls['provider'].setValue(null);
      this.displayClearButton.provider = false;
    }
  }

  // TODO: uncomment when show sub categories
  // adjustDisplayedChips() {
  //   const container = this.chipContainer?.nativeElement;

  //   if (container) {
  //     let totalWidth = 0;
  //     let visibleChips = [];
  //     const chips = this.selectedCategory?.subCategories ?? this.categoryData;

  //     for (const data of chips) {
  //       const chip = document.createElement('div');
  //       chip.className = 'chip d-inline-block m-0';
  //       chip.style.visibility = 'hidden';
  //       chip.innerText = typeof data === 'string' ? data : data.category;

  //       container.appendChild(chip);
  //       const chipWidth = chip.offsetWidth;
  //       container.removeChild(chip);

  //       if (totalWidth + chipWidth <= container.clientWidth - 150) {
  //         visibleChips.push(data);
  //         totalWidth += chipWidth;
  //       }
  //     }

  //     if (this.selectedCategory?.subCategories) {
  //       this.displayedSubCategories = visibleChips;
  //       this.tempDisplayedSubCategories = visibleChips;
  //     } else {
  //       this.displayedCategories = visibleChips;
  //       this.tempDisplayedCategories = visibleChips;
  //     }

  //     this.hiddenChips = this.selectedCategory
  //       ? this.selectedCategory.subCategories?.filter(
  //           (data) => !visibleChips.includes(data)
  //         ) || []
  //       : this.categoryData.filter((data) => !visibleChips.includes(data)) ||
  //         [];
  //   }
  // }
  // TODO: uncomment when show sub categories

  // adjustDisplayedChips() {
  //   const container = this.chipContainer?.nativeElement;

  //   if (container) {
  //     let totalWidth = 0;
  //     let visibleChips = [];
  //     const chips = this.categoryData;

  //     for (const data of chips) {
  //       const chip = document.createElement('div');
  //       chip.className = 'chip d-inline-block m-0';
  //       chip.style.visibility = 'hidden';
  //       chip.innerText = typeof data === 'string' ? data : data.category;

  //       container.appendChild(chip);
  //       const chipWidth = chip.offsetWidth;
  //       container.removeChild(chip);

  //       if (totalWidth + chipWidth <= container.clientWidth - 150) {
  //         visibleChips.push(data);
  //         totalWidth += chipWidth;
  //       }
  //     }

  //     // if (this.selectedCategory?.subCategories) {
  //     //   this.displayedSubCategories = visibleChips;
  //     //   this.tempDisplayedSubCategories = visibleChips;
  //     // } else {
  //     //   this.displayedCategories = visibleChips;
  //     //   this.tempDisplayedCategories = visibleChips;
  //     // }

  //     this.displayedCategories = visibleChips;
  //     this.tempDisplayedCategories = visibleChips;

  //     this.hiddenChips =
  //       this.categoryData.filter((data) => !visibleChips.includes(data)) || [];
  //   }
  // }

  onLocationFocusOut() {
    if (this.isLocationInputChanged) {
      this.mainSearchForm.controls['location'].setValue(null);
    }
  }

  onInputChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.isLocationInputChanged = true;
  }

  fetchLocations(keyword?: string) {
    this.isLoading.locations = true;
    this.locationList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getLocations({ ...(keyword && { keyword }) })
      .pipe(
        finalize(() => {
          this.isLoading.locations = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { success, data } = response;
          if (success) {
            if (data?.length) {
              this.locationList = response.data.map((location: any) => ({
                ...location,
                label: `${location.city}, ${location.st}`,
                value: location.city,
              }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting locations', error);
        },
      });
  }

  setMainSearchForm() {
    this.mainSearchForm = this._formBuilder.group({
      searchByState: ['HPS'],
      searchBy: ['service'],
      provider: [null],
      bundle: [null, Validators.required],
      location: [null, Validators.required],
      searchClicked: [false],
      optionClicked: [false],
      providers: [null],
      physicians: [null],
      radius: [null],
      physicianId: [null],
      pagination: [null],
      networkCode: [null],
    });
  }

  fetchBundle(keyword: string) {
    this.isLoading.bundles = true;
    this.bundleList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getBundles({
        keyword,
        networks: this.networkCode,
        associatedWithProviderOnly: true,
      })
      .pipe(
        finalize(() => {
          this.isLoading.bundles = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { data, success } = response;
          if (success) {
            if (data?.rows?.length) {
              const allBundleDisplayNames = data.rows.map(
                (bundle) => bundle.displayName
              );
              const uniqueBundlesByDisplayName = [
                ...new Set(allBundleDisplayNames),
              ];
              this.bundleList = [];
              uniqueBundlesByDisplayName.forEach((uniqueBundle) => {
                const bundles = data.rows.filter(
                  (data) => data.displayName === uniqueBundle
                );
                this.bundleList.push({
                  displayName: bundles[0]?.displayName,
                  bundleName: bundles[0]?.bundleName,
                  // serviceBundleId: bundles[0]?.serviceBundleId,
                  serviceBundleId: bundles
                    .map((bundle) => bundle.serviceBundleId)
                    .join(','),
                  label: bundles[0]?.displayName || bundles[0]?.bundleName,
                  value: bundles[0]?.bundleCode,
                });
              });
              // this.bundleList = data.rows.map((bundle: any) => ({
              //   // ...bundle,
              //   displayName: bundle?.displayName,
              //   bundleName: bundle?.bundleName,
              //   serviceBundleId: bundle?.serviceBundleId,
              //   label: `${bundle?.displayName || bundle?.bundleName} ${BUNDLE_NAME_CONCAT_STRING[bundle?.network?.code]}`,
              //   value: bundle?.bundleCode,
              // }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting bundles', error);
        },
      });
  }

  fetchProvider(keyword: string) {
    // this.isLoading.providers = true;
    this.providerList.length = 0;
    this.notFound && (this.notFound = false);
    this._mainService
      .getProviders({
        keyword,
        networks: this.networkCode,
      })
      .pipe(
        finalize(() => {
          // this.isLoading.bundles = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { data, success } = response;
          if (success) {
            let secondEntityTypeData: any = [];
            if (data?.length) {
              secondEntityTypeData = data.filter(
                (eachData) =>
                  eachData?.provider &&
                  (eachData.provider.displayName ||
                    eachData.provider.businessName ||
                    eachData.provider.firstName)
              );
            }

            if (secondEntityTypeData?.length) {
              this.providerList = secondEntityTypeData.map((data: any) => ({
                displayName: data?.provider.displayName,
                businessName: data?.provider.businessName,
                fullName: this._holistaUtility.getFullName(
                  data?.provider.firstName,
                  data?.provider.middleName,
                  data?.provider.lastName
                ),
                id: data?.provider.id,
                label:
                  data?.provider.displayName ||
                  data?.provider.businessName ||
                  this._holistaUtility.getFullName(
                    data?.provider.firstName,
                    data?.provider.middleName,
                    data?.provider.lastName
                  ),
                value: data?.provider.id,
                entityType: data?.provider.entityType,
              }));
            } else this.notFound = true;
          }
        },
        error: (error) => {
          console.log('Error getting bundles', error);
        },
      });
  }

  // onBundleDisplay = (bundle?): string | any => {
  //   if (typeof bundle === 'string') {
  //     return bundle;
  //   }
  //   return bundle
  //     ? bundle.label || bundle.displayName || bundle.bundleName || undefined
  //     : undefined;
  // };

  onBundleDisplay = (bundle?: any): string | undefined => {
    if (typeof bundle === 'string') {
      return bundle.length > 45 ? bundle.slice(0, 45) + '...' : bundle;
    }

    if (!bundle) return undefined;
    const value = bundle.label || bundle.displayName || bundle.bundleName;
    return value && value.length > 45 ? value.slice(0, 45) + '...' : value;
  };

  onProviderDisplay = (provider?): string | any => {
    if (typeof provider === 'string') {
      return provider;
    }
    return provider
      ? provider.label ||
          provider.displayName ||
          provider.businessName ||
          undefined
      : undefined;
  };

  onLocationDisplay = (location?): string | undefined => {
    if (typeof location === 'string') {
      return location;
    }
    return location ? location.label : undefined;
  };

  updateMainSearchFilter(event) {
    const {
      state,
      searchBy,
      searchClicked,
      location,
      bundle,
      radius,
      physicianId,
      pagination,
    } = event.searchForm;
    this.mainSearchForm.controls['searchBy'].setValue(searchBy);
    this.mainSearchForm.controls['searchByState'].setValue(state);
    this.mainSearchForm.controls['searchClicked'].setValue(searchClicked);
    this.mainSearchForm.controls['optionClicked'].setValue(
      typeof bundle === 'string' ? false : true
    );
    this.mainSearchForm.controls['bundle'].setValue(bundle);
    bundle && this.onBundleDisplay(bundle);
    this.mainSearchForm.controls['radius'].setValue(radius);
    this.mainSearchForm.controls['physicianId'].setValue(physicianId);
    this.mainSearchForm.controls['location'].setValue(location);
    this.mainSearchForm.controls['pagination'].setValue(pagination);
    if (radius) {
      this.providerSearchParams['radius'] = radius;
    } else {
      delete this.providerSearchParams['radius'];
    }
    if (physicianId) {
      this.providerSearchParams['physicianId'] = physicianId;
    } else {
      delete this.providerSearchParams['physicianId'];
    }
    this.searchProviders();
  }

  onScrollToElement(category: string) {
    setTimeout(() => {
      const element = document.querySelector(`[data-category="${category}"]`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          // inline: 'center',
        });
      }
    }, 0);
  }

  onServiceCategorySelect({
    data,
    isDropdownSelection = false,
  }: {
    data: ICategory;
    isDropdownSelection?: boolean;
  }) {
    this.selectedCategory = data;
    this.mainSearchForm.controls['bundle'].setValue(data.category);
    this.submitMainFilter();
    // A timeout is applied to ensure the width is calculated before the view is initialized

    // TODO: uncomment when show sub categories
    // setTimeout(() => {
    //   this.onToggleExpandHiddenChips({ isCategorySelected: true });
    // }, 0);
    // TODO: uncomment when show sub categories

    if (isDropdownSelection) {
      this.categoriesDropdownList.length = 0;
    }
  }

  onServiceSubCategorySelect({ value }: { value: string }) {
    this.mainSearchForm.controls['bundle'].setValue(
      value.toLowerCase() === 'all' ? this.selectedCategory.category : value
    );
    const bundleValue = this.mainSearchForm.get('bundle').value.toLowerCase();
    this.selectedSubCategory =
      this.selectedCategory.subCategories.find(
        (value) => value.toLowerCase() === bundleValue.toLowerCase()
      ) || '';
    this.submitMainFilter({ isSubCategorySelected: true });
  }

  getCategorizedActiveServices() {
    const bundleValue = this.mainSearchForm.get('bundle').value?.toLowerCase();

    if (this.categoryData?.length) {
      this.selectedCategory =
        this.categoryData.find(
          (data) => data.category.toLowerCase() === bundleValue?.toLowerCase()
        ) || null;
    }

    // TODO: uncomment when show sub categories
    // if (!this.selectedCategory) {
    //   this.selectedCategory = this.categoryData.find(
    //     (item) =>
    //       item.subCategories &&
    //       item.subCategories.some((sub) => sub?.toLowerCase() === bundleValue)
    //   );
    // }
    // this.selectedSubCategory =
    //   this.selectedCategory?.subCategories.find(
    //     (value) => value.toLowerCase() === bundleValue
    //   ) || '';
    // TODO: uncomment when show sub categories
  }

  // onToggleExpandHiddenChips(
  //   {
  //     isCategorySelected,
  //   }: {
  //     isCategorySelected: boolean;
  //   } = { isCategorySelected: false }
  // ) {
  //   this.showHiddenChips = isCategorySelected ? false : !this.showHiddenChips;

  //   if (!isCategorySelected) {
  //     this.adjustDisplayedChips();
  //   }

  //   if (!this.showHiddenChips) {
  //     if (this.selectedCategory) {
  //       this.displayedSubCategories = this.tempDisplayedSubCategories;
  //     } else {
  //       this.displayedCategories = this.tempDisplayedCategories;
  //     }
  //     this.getChipContainerHeight();
  //     return;
  //   }

  //   // TODO: uncomment when show sub categories
  //   // if (this.selectedCategory) {
  //   //   this.displayedSubCategories.push(...(this.hiddenChips as string[]));
  //   // } else {
  //   this.displayedCategories.push(...(this.hiddenChips as ICategory[]));
  //   // }
  //   // TODO: uncomment when show sub categories

  //   this.getChipContainerHeight();
  // }

  submitMainFilter({
    isSubCategorySelected,
    isModalSubmit,
  }: {
    isSubCategorySelected?: boolean;
    isModalSubmit?: boolean;
  } = {}) {
    this.isSearchClicked = true;
    if (this.isLocationInputChanged) {
      this.mainSearchForm.controls['location'].setValue(null);
    }

    if (this.mainSearchForm.valid) {
      this.searchListingHeight = 0;
      this.onViewportHeightChange.emit('auto');
      this.isFormSubmitted = true;
      this.showCategoriesAndAppAnnouncement = false;

      if (this.mainSearchForm.value?.pagination) {
        this.mainSearchForm.controls['pagination'].setValue(null);
        this.PAGINATION_QUERY = {
          ...HOLISTA_CONSTANT.defaultPaginationQuery,
        };
      }

      if (this.mainSearchForm.value.location) {
        const { lat, lng } = this.mainSearchForm.value.location;

        if (lat && lng) {
          this.providerSearchParams['radius'] =
            this.mainSearchForm.get('radius').value;
        }
        this.providerSearchParams['radius'] =
          this.mainSearchForm.get('radius').value;
      } else {
        this.mainSearchForm.controls['radius'].setValue(null);
      }

      if (!this.isSearchByProvider) {
        if (isModalSubmit) {
          this.getCategories();
        }
        this.getCategorizedActiveServices();

        // TODO: uncomment when show sub categories
        // if (this.selectedCategory?.subCategories?.length > 1) {
        //   const hasAllSelection = this.selectedCategory.subCategories.find(
        //     (category) => category === 'ALL'
        //   );
        //   if (!hasAllSelection) {
        //     this.selectedCategory?.subCategories.unshift('ALL');
        //   }
        // }
        // TODO: uncomment when show sub categories
      }

      this.mainSearchForm.controls['searchClicked'].setValue(true);
      this.mainSearchForm.controls['networkCode'].setValue(this.networkCode);
      this.mainSearchFormData = this.mainSearchForm.value;
      this.isMainPage.emit(
        !this.mainSearchFormData.searchClicked &&
          !this.mainSearchFormData.optionClicked
      );

      // TODO: uncomment when show sub categories
      // if (!isSubCategorySelected) {
      //   this.adjustDisplayedChips();
      // }
      // TODO: uncomment when show sub categories
      if (!this.isSearchByProvider) {
        this.onScrollToElement(
          this.mainSearchForm.get('bundle').value.toLowerCase()
        );
      }
      this.searchProviders();
    }
  }

  onViewChange() {
    setTimeout(() => {
      this.calculateHeight();
    }, 100);
  }

  searchProviders() {
    this.isLoading.providers = true;
    this.mainSearchForm.controls['providers'].setValue([]);
    this.mainSearchFormData = this.mainSearchForm.value;
    if (this.mainSearchForm.value?.pagination) {
      this.PAGINATION_QUERY = this.mainSearchForm.value.pagination;
    }
    const { totalCount, page, ...rest } =
      this.mainSearchForm.value.pagination || this.PAGINATION_QUERY;
    if (this.isSearchByProvider) {
      // delete this.providerSearchParams['serviceId'];
      if (
        this.providerSearchParams['entityType'] ==
        HOLISTA_CONSTANT.ENTITY_TYPE.PHYSICIAN
      ) {
        delete this.providerSearchParams['facilityId'];
      } else {
        delete this.providerSearchParams['physicianId'];
      }
    } else {
      delete this.providerSearchParams['facilityId'];
      delete this.providerSearchParams['physicianId'];
    }
    this._mainService
      .searchProviders({
        ...this.providerSearchParams,
        ...rest,
        networks: this.networkCode,
        associatedWithProviderOnly: true,
        keyword:
          this.mainSearchForm.get('bundle').value ??
          this.mainSearchForm.get('provider').value,
        keywordType: this.isSearchByProvider ? 'physician' : 'service',
      })
      .pipe(
        finalize(() => {
          this.isLoading.providers = false;
          this.isSearchClicked = false;
          setTimeout(() => {
            this.calculateHeight();
          }, 100);
        })
      )
      .subscribe({
        next: (response) => {
          if (response.success) {
            if (this.isSearchByProvider) {
              this.mainSearchForm.controls['providers'].setValue(
                response?.data?.rows || []
              );
            } else {
              this.mainSearchForm.controls['providers'].setValue(
                response?.data?.rows.filter((eachData) => eachData?.facility) ||
                  []
              );
            }
            this.PAGINATION_QUERY = {
              ...this.PAGINATION_QUERY,
              totalCount: response.data.count,
            };
            this.mainSearchForm.controls['pagination'].setValue(
              this.PAGINATION_QUERY
            );
            this.mainSearchFormData = this.mainSearchForm.value;
            this.isMainPage.emit(
              !this.mainSearchFormData.searchClicked &&
                !this.mainSearchFormData.optionClicked
            );
          }
        },
        error: (error) => {
          console.log('Error getting searched providers.', error);
        },
      });
  }

  onBundleOptionSelected(option: any) {
    if (this.isSearchByProvider) {
      this.displayClearButton.provider = true;
      const { id, entityType, isChipSelected } = option;
      this.providerSearchParams['entityType'] = entityType;
      this.providerSearchParams[
        entityType === HOLISTA_CONSTANT.ENTITY_TYPE.PHYSICIAN
          ? 'physicianId'
          : 'facilityId'
      ] = id;
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(
        HOLISTA_CONSTANT.defaultPaginationQuery
      );
      if (isChipSelected) {
        this.mainSearchForm.controls['provider'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onProviderDisplay(option);
        this.submitMainFilter();
      }
    } else {
      this.displayClearButton.service = true;
      const { serviceBundleId, isChipSelected } = option;
      // this.providerSearchParams['serviceId'] = serviceBundleId;
      this.mainSearchForm.controls['optionClicked'].setValue(true);
      this.mainSearchForm.controls['pagination'].setValue(
        HOLISTA_CONSTANT.defaultPaginationQuery
      );
      if (isChipSelected) {
        this.mainSearchForm.controls['bundle'].setValue(option);
        this.mainSearchForm.controls['optionClicked'].setValue(true);
        this.onBundleDisplay(option);
        this.submitMainFilter();
      }
    }
  }

  setNetworkCode(state: string) {
    if (state === 'WI') {
      this.networkCode = 'HPS';
    } else if (state === 'NC') {
      this.networkCode = 'NC';
    } else {
      this.networkCode = 'HPS, NC';
    }
  }

  setDefaultRadius() {
    this.mainSearchForm.controls['radius'].setValue(
      HOLISTA_CONSTANT.DEFAULT_RADIUS
    );
    this.providerSearchParams['radius'] = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  }

  onLocationOptionSelected(option?: any) {
    this.isLocationInputChanged = false;
    if (option) {
      this.setNetworkCode(option?.st);
      const { lng, lat } = option;
      this.providerSearchParams['latitude'] = lat;
      this.providerSearchParams['longitude'] = lng;
      if (!this.isSearchByProvider) {
        this.getCategories();
      }
    }
  }

  onRadiusSelected(data) {
    if (!this.isSearchByProvider) {
      this.getCategories();
    }
  }

  handleSelectCurrentLocationClick() {
    this.isLocationInputChanged = false;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const loc: any = await this._locationUtils.getLocationDetails(
            position
          );
          if (loc) {
            this.providerSearchParams['latitude'] = loc.lat;
            this.providerSearchParams['longitude'] = loc.lng;
            this.mainSearchForm.controls['location'].setValue({
              city: loc.city,
              label: loc.displayName,
              lat: loc.lat,
              lng: loc.lng,
              st: loc.state,
              value: loc.city,
              zip: loc.zip,
            });
            this.setNetworkCode(loc.state);
          } else {
            this.setNetworkCode(HOLISTA_CONSTANT.DEFAULT_LOCATION.st);
          }
          if (!this.isSearchByProvider) {
            this.getCategories();
          }
        },
        (error) => console.log(error.message)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  openSearchModal() {
    this.modalRef = this.modalService.open(SearchModalComponent, {
      data: {
        mainSearchFormData: this.mainSearchFormData,
        providerSearchParams: this.providerSearchParams,
      },
    });
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        const { mainSearchFormData, providerSearchParams } = data;
        this.isSearchByProvider = mainSearchFormData.searchBy === 'provider';

        if (this.isSearchByProvider) {
          this.alterFormValidationOnProviderSelect();
        } else {
          this.alterFormValidationOnServiceSelect();
        }

        this.providerSearchParams = {
          ...this.providerSearchParams,
          ...providerSearchParams,
        };
        this.mainSearchForm.patchValue(mainSearchFormData);
        this.mainSearchFormData = mainSearchFormData;
        this.submitMainFilter({ isModalSubmit: true });
      }
    });
  }

  alterFormValidationOnProviderSelect() {
    this.mainSearchForm.controls['bundle'].setValue(null);
    this.mainSearchForm.get('provider').setValidators([Validators.required]);
    this.mainSearchForm.get('bundle').clearValidators();
  }

  alterFormValidationOnServiceSelect() {
    this.mainSearchForm.controls['provider'].setValue(null);
    this.mainSearchForm.get('bundle').setValidators([Validators.required]);
    this.mainSearchForm.get('provider').clearValidators();
  }

  changeSearchBy(event) {
    this.mainSearchForm.controls['searchBy'].setValue(event.target.value);
    this.selectedCategory = {} as ICategory;
    this.onViewportHeightChange.emit('auto');
    this.isFormSubmitted = false;
    this.isSearchClicked = false;
    if (event.target.value === 'provider') {
      this.isSearchByProvider = true;
      this.alterFormValidationOnProviderSelect();
    } else {
      this.isSearchByProvider = false;
      this.alterFormValidationOnServiceSelect();
    }
    this.mainSearchForm.get('provider').updateValueAndValidity();
    this.mainSearchForm.get('bundle').updateValueAndValidity();
    this.clearAutocompleteSearch();
  }

  onShowCategoriesDropdown({ data }: { data: ICategory }) {
    if (this.categoriesDropdownList.length) {
      this.categoriesDropdownList.length = 0;
    } else {
      this.categoriesDropdownList = this.categoryData.filter(
        (cat) => cat.category.toLowerCase() !== data.category.toLowerCase()
      );
    }
  }

  onSlideCategories({ direction }: { direction: string }) {
    if (this.chipContainer) {
      const container = this.chipContainer.nativeElement;
      const scrollAmount =
        direction === 'right' ? container.clientWidth : -container.clientWidth;

      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth',
      });

      setTimeout(() => {
        this.isCategorySliderAtStart = container.scrollLeft === 0;
        this.isCategorySliderAtEnd =
          Math.abs(
            container.scrollLeft + container.clientWidth - container.scrollWidth
          ) <= 1;
      }, 300);
    }
  }

  onKeyDownEnter() {}

  // onStateChange() {
  //   this.networkCode = this.mainSearchForm.get('searchByState').value;
  // }
}
