import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'holista-provider-client';
  localVersion = localStorage.getItem('version');
  latestVersion: string;
  private intervalId: any;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.manageVersion();
    }, 120000); // Runs after two minutes interval
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  manageVersion() {
    this.http
      .get<{ version: string }>('/assets/version.json', {
        headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        }),
      })
      .subscribe((response) => {
        this.latestVersion = response.version;
        this.checkAndLoadVersion();
      });
  }

  checkAndLoadVersion() {
    if (this.localVersion) {
      if (this.localVersion !== this.latestVersion) {
        this.updateLocalVersion();
        this.forceReload();
      }
    } else {
      this.updateLocalVersion();
    }
  }

  updateLocalVersion() {
    localStorage.setItem('version', this.latestVersion);
  }

  forceReload() {
    caches
      .keys()
      .then((names) => {
        names.forEach((name) => caches.delete(name));
      })
      .finally(() => {
        window.location.reload();
      });
  }
}
