<div class="main-body-wrapper" *ngIf="!loading.networkConfig" [style.height]="viewportHeight">
  <div class="header" [ngStyle]="{ transform: isHeaderHidden ? 'translateY(-100%)' : 'translateY(0)' }">
    <app-header [config]="config"></app-header>
  </div>
  <div class="main-body">
    <app-main-body (isMainPage)="checkIsMainPage($event)" (onViewportHeightChange)="onViewportHeightChange($event)"></app-main-body>
  </div>
  <app-footer [config]="config"></app-footer>
</div>
<div class="d-flex align-items-center justify-content-center h-100" *ngIf="loading.networkConfig">
  <app-loader [loader]="true"></app-loader>
</div>