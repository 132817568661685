import {
  Inject,
  Component,
  ChangeDetectorRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ComponentUtils, HolistaUtils, StorageUtility } from 'src/app/utils';
import { DOCUMENT } from '@angular/common';
import { MainService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { HOLISTA_CONSTANT, NETWORK_CODES } from 'src/app/constants';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss'],
})
export class LayoutMainComponent implements OnInit {
  environment = environment;
  isMainPage: boolean = true;
  // mainSearchHeight: any;
  config: any;
  themeCode = 'HPS';
  loading = {
    networkConfig: false,
  };
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  isHeaderHidden = false;
  lastScrollY = 0;
  viewportHeight = 'auto';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private mainService: MainService,
    private route: ActivatedRoute,
    private _storageUtility: StorageUtility,
    private cdRef: ChangeDetectorRef,
    private _componentUtils: ComponentUtils,
    public _holistaUtils: HolistaUtils
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculateContainerHeight();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollY = window.scrollY || document.documentElement.scrollTop;
    if (currentScrollY > this.lastScrollY && currentScrollY > 60) {
      this.isHeaderHidden = true;
    }
    if (currentScrollY < 24) {
      this.isHeaderHidden = false;
    }
    this.lastScrollY = currentScrollY;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const networkCode = params['networks'];
      if (networkCode && networkCode !== this.themeCode) {
        this.themeCode = networkCode;
      } else {
        this.themeCode = 'HPS';
      }
      this.getNetworkConfiguration(this.themeCode);
    });
  }

  ngAfterViewInit(): void {
    this.calculateContainerHeight();
  }

  private calculateContainerHeight() {
    // const headerHeight = this._componentUtils.getComponentHeight('app-header');
    // const footerHeight = this._componentUtils.getComponentHeight('app-footer');
    // const windowHeight = window.innerHeight;
    // const containerHeight =
    //   this._componentUtils.getComponentHeight('app-search-listing');
    // const mainBodyHeight =
    //   this._componentUtils.getComponentHeight('.main-search');

    // if (this.isMainPage) {
    //   const resizedHeight = windowHeight - headerHeight - footerHeight;
    //   this.mainSearchHeight = `${resizedHeight + 280}px`;
    // } else {
    //   if (containerHeight) {
    //     this.mainSearchHeight = 0;
    //     const resizedHeight =
    //       containerHeight +
    //       headerHeight / 2 +
    //       footerHeight +
    //       (mainBodyHeight - 70);
    //     this.mainSearchHeight = `${resizedHeight}px`;
    //   } else {
    //     this.mainSearchHeight = `200vh`;
    //   }
    // }
    this.cdRef.detectChanges();
  }

  checkIsMainPage(event) {
    this.isMainPage = event;
    setTimeout(() => {
      this.calculateContainerHeight();
    }, 250);
  }

  onViewportHeightChange(event: string) {
    this.viewportHeight = event;
  }

  getNetworkConfiguration(networkCode) {
    this.loading.networkConfig = true;
    this.mainService
      .getNetworkConfigs(networkCode)
      .pipe(
        finalize(() => {
          this.loading.networkConfig = false;
        })
      )
      .subscribe({
        next: (response) => {
          const { success, data } = response;
          if (success) {
            if (data?.code) {
              this._storageUtility.set('local', 'networkConfig', data);
              this._storageUtility.set('local', 'networkCode', NETWORK_CODES);
              // this._storageUtility.set('local', 'networkCode', data.code);
              this.config = data;
              this.themeCode = data.code;
            } else {
              this.themeCode = 'HPS';
              this._storageUtility.set('local', 'networkCode', NETWORK_CODES);
            }
          }
          const bodyElement = this._document.getElementById(
            'body'
          ) as HTMLElement;
          if (bodyElement) {
            bodyElement.setAttribute('class', this.themeCode + '-skin');
          }
          setTimeout(() => {
            this.calculateContainerHeight();
          }, 200);
        },
        error: (error) => {
          console.log('Error getting network config', error);
        },
      });
  }
}
