import { Subject, of } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  mergeMap,
} from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { LocationUtils, StorageUtility, HolistaUtils } from 'src/app/utils';
import { MainService } from '../../../services';
import { DetailModalComponent } from 'src/app/modals';
const telehealthConsultantImg =
  '../../../assets/img/svg/telehealth-consultant.svg';

@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.component.html',
  styleUrls: ['./search-listing.component.scss'],
})
export class SearchListingComponent implements OnInit {
  HOLISTA_CONSTANT = HOLISTA_CONSTANT;
  searchAdvanceFilterForm: FormGroup;
  searchBundleText = new Subject();
  searchLocationText = new Subject();
  notFound = false;
  listingSearchFilter: any;
  showSuggestions: boolean = false;
  // owlCarouselOptions = this._holistaUtils.deepClone(HOLISTA_CONSTANT.owlCarouselOptions);
  config = JSON.parse(JSON.stringify(HOLISTA_CONSTANT.modalConfig));
  isLoading = {
    bundles: false,
    locations: false,
  };
  DEFAULT_RADIUS = HOLISTA_CONSTANT.DEFAULT_RADIUS;
  DEFAULT_MILWAUKEE_COORDINATES = HOLISTA_CONSTANT.DEFAULT_LOCATION;
  isTableView = true;
  // doctors: any[] = [];
  radius = HOLISTA_CONSTANT.RADIUS;
  mainSearchFiltersData: any = {};
  customOptions: OwlOptions = {};
  chipList: any[] = [];
  telehealthConsultantImg = telehealthConsultantImg;

  @Input() set mainSearchFilters(data) {
    if (!this.searchAdvanceFilterForm) {
      this.setSearchAdvanceFilterForm();
    }
    this.listingSearchFilter = data;
    if (data) {
      this.mainSearchFiltersData = {};
      const {
        bundle,
        location,
        searchClicked,
        optionClicked,
        radius,
        networkCode,
      } = data;
      this.searchAdvanceFilterForm.controls['radius'].setValue(radius);
      // if (!optionClicked) {
      //   let keyword = '';
      //   if (bundle) {
      //     keyword =
      //       typeof data.bundle === 'string'
      //         ? data.bundle.toLowerCase()
      //         : data.bundle.displayName.toLowerCase() ||
      //           data.bundle.bundleName.toLowerCase();
      //   } else {
      //     keyword =
      //       typeof data.provider === 'string'
      //         ? data.provider.toLowerCase()
      //         : data.provider.displayName.toLowerCase() ||
      //           data.provider.businessName.toLowerCase();
      //   }
      //   this.loadingProviders = true;
      //   this.isLoading.bundles = true;
      //   const queryParams = {
      //     keyword,
      //     limit: HOLISTA_CONSTANT.defaultPaginationQuery.limit,
      //     networks: networkCode,
      //   };
      //   const request = this.isSearchByProvider
      //     ? this._mainService.getProviders(queryParams)
      //     : this._mainService.getBundles(queryParams);
      //   request
      //     .pipe(
      //       finalize(() => {
      //         this.isLoading.bundles = false;
      //         this.loadingProviders = false;
      //       })
      //     )
      //     .subscribe({
      //       next: (response) => {
      //         const { data, success } = response;
      //         if (success) {
      //           this.chipList = [];
      //           let providerTypeData: any = [];
      //           if (this.isSearchByProvider) {
      //             if (data && data.length) {
      //               providerTypeData = data.filter(
      //                 (eachData) =>
      //                   eachData?.provider &&
      //                   (eachData.provider.displayName ||
      //                     eachData.provider.businessName ||
      //                     eachData.provider.firstName)
      //               );
      //               if (providerTypeData?.length) {
      //                 this.chipList = providerTypeData.map(({ provider }) => ({
      //                   displayName: provider.displayName,
      //                   businessName: provider.businessName,
      //                   fullName: this._holistaUtility.getFullName(
      //                     provider?.firstName,
      //                     provider?.middleName,
      //                     provider?.lastName
      //                   ),
      //                   id: provider.id,
      //                   label: provider.displayName || provider.businessName,
      //                   value: provider.id,
      //                   entityType: provider.entityType,
      //                 }));
      //               }
      //             } else this.notFound = true;
      //           } else {
      //             if (data?.rows?.length) {
      //               this.chipList = data.rows.map(
      //                 ({ displayName, bundleName, serviceBundleId }) => ({
      //                   displayName,
      //                   bundleName,
      //                   serviceBundleId,
      //                 })
      //               );
      //             } else this.notFound = true;
      //           }
      //         }
      //       },
      //       error: (error) => {
      //         console.log('Error getting bundles', error);
      //       },
      //     });
      // }
      this.mainSearchFiltersData = data;
      // if (searchClicked && !optionClicked) {
      //   this.showSuggestions = true;
      // } else this.showSuggestions = false;
    }
  }

  @Input() loadingProviders: boolean;
  @Input() isSearchByProvider: boolean;
  @Input() isSmallScreen: boolean;
  @Input() isFormSubmitted: boolean;
  @Input() hasCategoriesRow: boolean;
  @Output() updateMainSearchFilter = new EventEmitter<any>();
  @Output() onBundleOptionSelected = new EventEmitter<any>();
  @Output() onLocationOptionSelected = new EventEmitter<any>();
  @Output() onViewChange = new EventEmitter<any>();

  detailModalRef: MdbModalRef<DetailModalComponent> | null = null;

  constructor(
    private _formBuilder: FormBuilder,
    private _mainService: MainService,
    private _storageUtility: StorageUtility,
    private _locationUtility: LocationUtils,
    private _holistaUtility: HolistaUtils,
    private modalService: MdbModalService
  ) {
    this.setSearchAdvanceFilterForm();
  }

  ngOnInit(): void {
    this.isTableView = !this.isSmallScreen;
  }

  setSearchAdvanceFilterForm() {
    this.searchAdvanceFilterForm = this._formBuilder.group({
      doctor: [null],
      radius: [null],
    });
  }

  submitListingFilter() {
    this.mainSearchFiltersData = null;
    this.chipList.length && (this.chipList.length = 0);
  }

  bundleOptionSelected(option: any, isChipSelected: boolean = false) {
    if (isChipSelected) {
      this.loadingProviders = true;
      this.mainSearchFiltersData = null;
    }
    this.onBundleOptionSelected.emit({ ...option, isChipSelected });
    if (isChipSelected) {
      this.showSuggestions = false;
    }
  }

  getDirection(item) {
    if (item?.facility?.location || item?.physician?.location) {
      const { address } = item?.facility || item?.physician;
      let stringifiedAddress = '';
      if (address) {
        stringifiedAddress = Object.values(address)
          .filter((x) => x)
          .join(' ');
      }
      const { location } = this.listingSearchFilter;
      if (location?.lat && location?.lng) {
        this._locationUtility.getDirection(
          `${location?.label} ${location?.city} ${location?.st} ${location?.zip}`,
          `${stringifiedAddress}`
        );
      } else {
        this._locationUtility.getDirection('', `${stringifiedAddress}`);
      }
    }
  }

  onRadiusSelected(radius) {
    const updatedListingSearchFilter = {
      ...this.listingSearchFilter,
      radius,
    };
    this.listingSearchFilter = updatedListingSearchFilter;
    this.updateMainSearchFilter.emit({
      searchForm: updatedListingSearchFilter,
    });
    this.submitListingFilter();
  }

  loadMorePageCount: number = 0;

  pageChanged(event, isLoadMore = false) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    let offset;
    if (isLoadMore) {
      this.loadMorePageCount += 1;
      offset = this.loadMorePageCount;
    } else {
      offset = event - 1;
    }
    const updatedListingSearchFilter = {
      ...this.listingSearchFilter,
      pagination: {
        ...this.listingSearchFilter.pagination,
        offset,
        page: isLoadMore ? this.loadMorePageCount : event,
      },
    };
    this.updateMainSearchFilter.emit({
      searchForm: updatedListingSearchFilter,
    });
    this.submitListingFilter();
  }

  viewDetailModal(item) {
    this.detailModalRef = this.modalService.open(DetailModalComponent, {
      ...HOLISTA_CONSTANT.modalConfig,
      modalClass: `modal-dialog-scrollable modal-lg ${
        this.isSmallScreen ? '' : 'modal-dialog-centered'
      }`,

      data: {
        providerData: item,
        listingSearchFilter: this.listingSearchFilter,
        isSearchByProvider: this.isSearchByProvider,
      },
    });
  }

  getFullName(physician: any): string {
    return [physician?.firstName, physician?.middleName, physician?.lastName]
      .filter((namePart) => !!namePart)
      .join(' ');
  }

  getHeight(facility: any): string {
    return facility ? (this.isSmallScreen ? '390px' : '400px') : '352px';
  }

  onChangeView() {
    this.isTableView = !this.isTableView;
    this.onViewChange.emit();
  }

  onRedirectGettingStarted(item) {
    this._holistaUtility.customRedirect(
      HOLISTA_CONSTANT.HOLISTA_GET_STARTED,
      true
    );
  }

  // onDoctorSelected(physicianId) {
  //   const updatedListingSearchFilter = {
  //     ...this.listingSearchFilter,
  //     physicianId,
  //   };
  //   this.listingSearchFilter = updatedListingSearchFilter;
  //   this.updateMainSearchFilter.emit({ searchForm: updatedListingSearchFilter });
  //   this.submitListingFilter();
  // }
}
