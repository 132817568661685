<button type="button" class="btn-close position-absolute" aria-label="Close" (click)="modalRef.close()"
  style="right: 10px; top: 10px; z-index: 1;"></button>
<div class="modal-body scrollbar scrollbar-primary thin" style="max-height: 550px; min-height: 550px;">
  <div>
    <div class="d-flex card-body">
      <div class="contact-img">
        <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-hospital fa-lg text-primary d-flex align-items-center justify-content-center"
          style="height: 64px; width: 64px;"></i>
      </div>
      <!-- For Facility -->
      <div class="contact-info ms-2 me-4" *ngIf="providerData?.facility">
        <div class="name-speciality">
          <p class="h6 m-0 cursor-pointer"
            [mdbTooltip]="providerData?.facility?.displayName || providerData?.facility?.businessName">
            {{ providerData?.facility?.displayName || providerData?.facility?.businessName | slice:0:50 }}
            <span
              *ngIf="providerData?.facility?.displayName?.length > 50 || providerData?.facility?.businessName?.length > 50">...</span>
          </p>
          <p class="m-0 text-light">
            <small>{{ providerData?.facility?.primaryTaxonomy && providerData?.facility?.primaryTaxonomy[0]?.name
              }}</small>
          </p>
        </div>
        <div class="address-phone mt-1">
          <div class="address d-flex">
            <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
            <p class="m-0">
              <small class="text-gray">{{ providerData?.facility?.address?.addressLine1 }}</small><br>
              <small class="text-gray">{{ providerData?.facility?.address?.city }}, {{
                providerData?.facility?.address?.state
                }}, {{ providerData.facility?.address?.zip | zipFormat}}</small><br>
              <small class="text-primary text-decoration-underline cursor-pointer"
                (click)="getDirection(providerData)">(Get Directions)</small>
            </p>
          </div>
          <div class="phone mt-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                <p class="m-0">
                  <small class="text-gray">{{ providerData?.facility?.phone && providerData?.facility?.phone !== 'N/A' ? (providerData.facility.phone | mask:
                    '(000) 000-0000')
                    : 'N/A' }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- For Physician -->
      <div class="contact-info ms-2 me-4 d-flex align-items-center" *ngIf="providerData?.physician">
        <div class="name-speciality">
          <p class="h6 m-0 cursor-pointer"
            [mdbTooltip]="providerData?.physician?.displayName || providerData?.physician?.businessName || getFullName(providerData?.physician)">
            {{ providerData?.physician.fullName || providerData?.physician?.displayName || providerData?.physician?.businessName || getFullName(providerData?.physician) | slice:0:50 }}
            <span
              *ngIf="providerData?.physician?.displayName?.length > 50 || providerData?.physician?.businessName?.length > 50 || getFullName(providerData?.physician).length > 50">...</span>
          </p>
          <p class="m-0 text-light">
            <small>{{ providerData?.physician?.primaryTaxonomy && providerData?.physician?.primaryTaxonomy[0]?.name
              }}</small>
          </p>
          <div class="address-phone mt-1">
            <div class="address d-flex">
              <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
              <p class="m-0">
                <small class="text-gray">{{ providerData?.physician?.address?.addressLine1 }}</small><br>
                <small class="text-gray">{{ providerData?.physician?.address?.city }}, {{
                  providerData?.physician?.address?.state
                  }}, {{ providerData.physician?.address?.zip | zipFormat}}</small><br>
              </p>
            </div>
            <div class="phone mt-2">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                  <p class="m-0">
                    <small class="text-gray">{{ providerData?.physician?.phone && providerData?.physician?.phone !== 'N/A' ? (providerData.physician.phone | mask:
                      '(000) 000-0000')
                      : 'N/A' }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <mdb-tabs #tabs (activeTabChange)="onTabChange($event)">
        <mdb-tab title="{{ subTitle }}">
          <div class="mb-2">
            <small class="p-1" style="background-color: #ebebeb; border-radius: 4px; font-size: 12px;">
              <i class="fas fa-info-circle text-light"></i> For help finding a {{subTitle | lowercase}}, contact: <a class="text-primary text-decoration-underline" href="tel:+2628346126">(262) 834 6126</a>
            </small>
          </div>

          <!-- Table for Skeleton Loaders (Shown while loading) -->
          <table class="table" *ngIf="isLoading.providerList">
            <thead>
              <tr>
                <th scope="col"><app-skeleton-loader></app-skeleton-loader></th>
                <th scope="col"><app-skeleton-loader></app-skeleton-loader></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let i of [1,2]">
                <td>
                  <div class="d-flex align-items-center gap-2">
                    <app-skeleton-loader height="48px" width="48px" borderRadius="50%"></app-skeleton-loader>
                    <div class="d-flex row gap-2" [ngStyle]="{'width': '80%'}">
                      <div class="w-100"><app-skeleton-loader></app-skeleton-loader></div>
                      <div class="w-100"><app-skeleton-loader></app-skeleton-loader></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex row gap-2">
                    <app-skeleton-loader></app-skeleton-loader>
                    <app-skeleton-loader></app-skeleton-loader>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Table for Data (Shown when loading is complete) -->
          <table class="table" *ngIf="!isLoading.providerList && providerList.length">
            <thead>
              <tr>
                <th scope="col">Provider Name</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of providerList">
                <td scope="row">
                  <div class="d-flex align-items-center gap-2">
                    <div class="contact-img">
                      <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-user-doctor fa-lg text-primary d-flex align-items-center justify-content-center"
                        style="height: 50px; width: 50px;"></i>
                    </div>
                    <div>
                      {{ item?.name }}
                      <p class="m-0 text-light">
                        <small>{{ item?.primaryTaxonomy && item?.primaryTaxonomy[0]?.name }}</small>
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="address d-flex">
                    <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                    <p class="m-0">
                      <small class="text-gray">{{ item?.address?.addressLine1 }}</small><br>
                      <small class="text-gray">{{ item?.address?.city }}, {{ item?.address?.state }}, {{ item.address?.zip | zipFormat}}</small><br>
                      <small *ngIf="isSearchByProvider" class="text-primary text-decoration-underline cursor-pointer"
                        (click)="getDirection(item)">(Get Directions)</small>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Showing of results found -->
          <div class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
            *ngIf="!isLoading.providerList && !providerList.length">
            <div class="text-center mt-3">
              <i class="fa-solid fa-user-doctor fa-2xl text-primary"></i>
              <p class="mt-2"><small class="text-muted">{{ subTitle }} not found.</small></p>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab title="Services">
          <div class="mb-3">
            <small class="p-1" style="background-color: #ebebeb; border-radius: 4px; font-size: 12px;">
              <i class="fas fa-info-circle text-light"></i> Learn more about starting an Episode of Care: <a class="text-primary text-decoration-underline cursor-pointer" (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_GET_STARTED, true)">Let’s Get Started</a>
            </small>
          </div>
          <div class="row g-2" [class.px-1]="!isSmallScreen" *ngIf="isLoading.serviceList || serviceList.length">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 service-list" [ngClass]="{'d-flex': serviceList?.length}" *ngFor="let item of (serviceList?.length ? serviceList : [1,2,3,4,5,6,7,8])">
              <ng-template #showLoader>
                <app-skeleton-loader></app-skeleton-loader>
              </ng-template>
              <div class="d-flex gap-2 align-items-start" *ngIf="!isLoading.serviceList && serviceList.length; else showLoader">
                <i class="fa-regular fa-circle-check text-primary" style="margin-top: 2px;"></i>
                <small>{{ item?.displayName || item?.bundleName }}</small>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-start py-4 border no-conversation-container h-100"
            *ngIf="!isLoading.serviceList && !serviceList.length">
            <div class="text-center mt-3">
              <i class="fa-solid fa-calendar-days fa-2xl text-primary"></i>
              <p class="mt-2"><small class="text-muted">Services not found.</small></p>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabs>
    </div>
  </div>
</div>