import { Component, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbTabChange, MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { finalize } from 'rxjs';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { MainService } from 'src/app/services';
import { HolistaUtils, LocationUtils } from 'src/app/utils';

@Component({
  selector: 'app-detail-modal',
  templateUrl: './detail-modal.component.html',
  styleUrls: ['./detail-modal.component.scss'],
})
export class DetailModalComponent implements OnInit {
  providerData: any;
  listingSearchFilter: any;
  isSearchByProvider: boolean;
  DEFAULT_MILWAUKEE_COORDINATES = HOLISTA_CONSTANT.DEFAULT_LOCATION;
  @ViewChild('tabs') tabs!: MdbTabsComponent;
  providerList: any[] = [];
  serviceList: any[] = [];
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  isLoading = {
    providerList: false,
    serviceList: false,
  };
  providerId: any;
  subTitle: string;
  isSmallScreen: boolean = false;

  constructor(
    public modalRef: MdbModalRef<DetailModalComponent>,
    private _locationUtility: LocationUtils,
    private _mainService: MainService,
    public _holistaUtils: HolistaUtils
  ) {}

  ngOnInit(): void {
    this.isSmallScreen = window.innerWidth <= 676;
    this.providerId = this.isSearchByProvider
      ? this.providerData.physician?.providerId
      : this.providerData.facility?.providerId;
    if (!this.isSearchByProvider) {
      this.subTitle = 'Physicians';
    } else {
      this.subTitle = 'Facilities';
    }
  }

  getDirection(provider) {
    if (provider?.facility?.location || provider?.location) {
      const { address } = provider?.facility || provider;
      let stringifiedAddress = '';
      if (address) {
        stringifiedAddress = Object.values(address)
          .filter((x) => x)
          .join(' ');
      }
      const { location } = provider?.facility || provider;
      if (location?.lat && location?.lng) {
        this._locationUtility.getDirection(
          `${location?.label} ${location?.city} ${location?.st} ${location?.zip}`,
          `${stringifiedAddress}`
        );
      } else {
        this._locationUtility.getDirection('', `${stringifiedAddress}`);
      }
    }
  }

  onLinkClick(link: string, newTab?: boolean) {
    this._holistaUtils.customRedirect(link, newTab);
  }

  fetchProviderList() {
    this.providerList = [];
    this.isLoading.providerList = true;
    const _services = !this.isSearchByProvider
      ? this._mainService.getPhysicianDetail(this.providerId, {
          networks: this.listingSearchFilter.networkCode,
        })
      : this._mainService.getFacilityDetail(this.providerId, {
          networks: this.listingSearchFilter.networkCode,
        });
    _services
      .pipe(
        finalize(() => {
          this.isLoading.providerList = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.success && response?.data?.length) {
            this.providerList = response.data.map((x) => ({
              ...x,
              adaptedPrimaryTaxonomy:
                x.primaryTaxonomy && x.primaryTaxonomy.length
                  ? x.primaryTaxonomy.map((x) => x.name).join(', ')
                  : '',
            }));
          }
        },
        error: (error) => {
          console.log('Error getting physicians.', error);
        },
      });
  }

  fetchServiceList() {
    this.isLoading.serviceList = true;
    let params = {};
    if (!this.isSearchByProvider) {
      params = {
        facilityId: this.providerId,
        networks: this.listingSearchFilter.networkCode,
      };
    } else {
      params = {
        physicianId: this.providerId,
        networks: this.listingSearchFilter.networkCode,
      };
    }
    this._mainService
      .searchProviders(params)
      .pipe(
        finalize(() => {
          this.isLoading.serviceList = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.success && response?.data?.rows?.length) {
            this.serviceList = response.data.rows.map((x) => ({
              displayName: x.service?.displayName,
              bundleName: x.service?.bundleName,
              serviceMode: x.service.serviceMode?.toLowerCase(),
            }));
          }
        },
        error: (error) => {
          console.log('Error getting services.', error);
        },
      });
  }

  onTabChange(event: MdbTabChange): void {
    switch (event.index) {
      case 0:
        if (!this.providerList.length) this.fetchProviderList();
        break;
      case 1:
        if (!this.serviceList.length) this.fetchServiceList();
        break;
      default:
        break;
    }
  }

  getFullName(physician: any): string {
    return [physician?.firstName, physician?.middleName, physician?.lastName]
      .filter((namePart) => !!namePart)
      .join(' ');
  }
}
