import { Component, Input, OnInit } from '@angular/core';
import {
  MdbPopconfirmRef,
  MdbPopconfirmService,
} from 'mdb-angular-ui-kit/popconfirm';
import { ContactPopupComponent } from 'src/app/components';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { HolistaUtils } from 'src/app/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  logoPath: string = 'assets/img/svg/Logo.svg';
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  networkConfig: any;

  @Input() set config(data) {
    if (data?.code) {
      this.networkConfig = data;
    }
  }

  popconfirmRef: MdbPopconfirmRef<ContactPopupComponent> | null = null;

  constructor(
    public _holistaUtils: HolistaUtils,
    private popconfirmService: MdbPopconfirmService
  ) {}

  onLinkClick(link: string, newTab?: boolean) {
    this._holistaUtils.customRedirect(link, newTab);
  }

  openContactPopup(event) {
    const target = event.target as HTMLElement;
    this.popconfirmRef = this.popconfirmService.open(
      ContactPopupComponent,
      target,
      { popconfirmMode: 'modal' }
    );
  }

  navigateToHome() {
    window.open(location.href, '_self');
  }
}
