<div class="flex" [ngClass]="{
    'justify-start': alignment === 'start',
    'justify-center': alignment === 'center',
    'justify-end': alignment === 'end'
  }">
  <div
    class="skeleton-loader"
    [ngStyle]="{
      'height': height,
      'width': width || randomWidth,
      'border-radius': borderRadius
    }"
  ></div>
</div>